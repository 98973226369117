import { IonCol, IonLoading, IonRow, IonToast } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useLocation } from 'react-router';
import { CharacterShow } from '../components/characters';
import { EXCEPTIONS } from '../constants';
import {
  DeviceContext,
  DeviceContextModel,
  TranslationContext,
  UserContext,
} from '../contexts';
import { analyticsHelper, authenticationHelper } from '../helpers';
import { webAnalyticsHelper } from '../helpers/webAnalytics';
import { authResource, routerResource } from '../resource';
import './characterPage.scss';
import './conversationPage.scss';

interface Props
  extends RouteComponentProps<{
    code: string;
  }> {}

export const CharacterPage: React.FC<Props> = ({ match }) => {
  const { translate } = useContext(TranslationContext);
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(
    translate('global.serverError'),
  );
  const device = useContext(DeviceContext) as DeviceContextModel;
  const { setUserInfos } = useContext(UserContext);
  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');

  useEffect(() => {
    const init = async () => {
      await registerOrSignIn();
    };

    init();
  }, []);

  const signin = async () => {
    try {
      const response = await authResource.create({
        identification: device.uuid,
        password: process.env.REACT_APP_DEFAULT_DEVICE_AUTH_PASSWORD,
      });
      setUserInfos(response);
      // Add user id to analytics
      analyticsHelper.setUser(response['@id']);
      analyticsHelper.logEvent('login', {
        Device: device.model,
        Version: device.appVersion,
        OS: device.operatingSystem,
      });
      webAnalyticsHelper.setUser(response['@id']);
      webAnalyticsHelper.logEvent('login', {
        Device: device.model,
        Version: device.appVersion,
        OS: device.operatingSystem,
      });
    } catch (error) {
      if (error.message === EXCEPTIONS.INVALID_CREDENTIALS) {
        authenticationHelper.unsetDeviceIdUsed();
      } else {
        setDisplayError(true);
        throw error;
      }
    }
  };

  const registerToken = async () => {
    try {
      const response = await routerResource.create({
        token,
        deviceIdentifier: device.uuid,
      });
      setUserInfos(response);
    } catch (error) {
      if (error.message === EXCEPTIONS.DEVICE_ID_ALREADY_USED) {
        setErrorMessage(translate('global.deviceIdError'));
      }
      setDisplayError(true);
      throw error;
    }
    setLoading(false);

    authenticationHelper.setDeviceIdUsed();
  };

  const registerOrSignIn = async () => {
    setLoading(true);
    setDisplayError(false);
    if (token) {
      try {
        const isSigningUp = !authenticationHelper.isDeviceIdUsed();
        await registerToken();
        analyticsHelper.logEvent(isSigningUp ? 'sign_up' : 'recharge_balance', {
          Device: device.model,
          Version: device.appVersion,
          OS: device.operatingSystem,
        });
        webAnalyticsHelper.logEvent(
          isSigningUp ? 'sign_up' : 'recharge_balance',
          {
            Device: device.model,
            Version: device.appVersion,
            OS: device.operatingSystem,
          },
        );
      } catch (error) {
        if (error.message === EXCEPTIONS.EMAIL_ALREADY_USED) {
          // sign in with email ?
          setDisplayError(true);
          setLoading(false);
          throw error;
        }
        if (error.message !== EXCEPTIONS.DEVICE_ID_ALREADY_USED) {
          setDisplayError(true);
          setLoading(false);
          throw error;
        }
      }
    } else {
      await signin();
    }
    setLoading(false);
  };

  return (
    <IonRow className="expert-layout bla-theme">
      <IonCol sizeMd="3" className="ion-hide-sm-down">
        <img src="/assets/img/bla/i18n/en-US/left-blanche.png" alt={''} />
      </IonCol>
      <IonCol sizeMd="7" className="chat-frame">
        <CharacterShow code={match.params.code} />
        <IonLoading isOpen={loading} message="Please wait..." />
        <IonToast
          isOpen={displayError}
          message={errorMessage}
          position="bottom"
        />
      </IonCol>
    </IonRow>
  );
};
