import React, { Component, FC } from 'react';
import { conversationHelper } from '../helpers';
import { Character, Message, MessageType } from '../models';
import { conversationHubResource } from '../resource';

interface InputProps {
  character: Character | null;
  idCustomer: string;
  idExpert: string;
}

interface States {
  discussion: Message[];
}

interface OutputProps {
  character: Character | null;
  discussion: Message[];
  send: (
    conversation: string,
    message: string,
    type?: MessageType,
    author?: string,
    authorIdentifier?: string,
    authorType?: string,
    conversationBetween?: object,
  ) => void;
}

export const withConversation = (WrappedComponent: FC<OutputProps>) =>
  class Conversation extends Component<InputProps, States> {
    private buffer: Message[] = [];
    private eventSource!: EventSource;
    private timer?: any;

    constructor(props: InputProps) {
      super(props);

      this.state = {
        discussion: [],
      };

      const { idCustomer, idExpert } = props;
      this.setEventSource(idExpert, idCustomer);
      this.send = this.send.bind(this);
    }

    // wait some milliseconds to store messages before sending everything
    addWithBuffer({ data, lastEventId }: any) {
      const message = JSON.parse(data) as Message;
      this.buffer.push(message);

      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.timer = setTimeout(() => {
        this.setState({
          discussion: [...this.state.discussion, ...this.buffer],
        });

        // if subscriber is not in discovery mode we store last message id
        if (this.props.idCustomer !== '{}') {
          conversationHelper.setLastId(message['@id'], lastEventId);
        }

        this.buffer = [];
      }, 500);
    }

    closeEventSource() {
      if (this.eventSource) {
        this.eventSource.close();
        this.setState({ discussion: [] });
      }
    }

    componentDidUpdate(prevProps: InputProps) {
      if (
        prevProps.idCustomer !== this.props.idCustomer ||
        prevProps.idExpert !== this.props.idExpert
      ) {
        this.closeEventSource();
        this.setEventSource(this.props.idExpert, this.props.idCustomer);
      }
    }

    componentWillUnmount() {
      this.closeEventSource();
      if (this.timer) {
        clearTimeout(this.timer);
      }
    }

    render() {
      return (
        <WrappedComponent
          character={this.props.character}
          discussion={this.state ? this.state.discussion : []}
          send={this.send}
        />
      );
    }

    send(
      conversation: string,
      message: string,
      type: MessageType = MessageType.MESSAGE,
      author?: string,
      authorIdentifier?: string,
      authorType?: string,
      conversationBetween?: object,
    ): Promise<string> {
      const { idCustomer, idExpert } = this.props;
      return conversationHubResource.sendMessage(
        `/conversations/${idExpert}/${idCustomer}`,
        { idCustomer, idExpert },
        conversation,
        type,
        message,
        author,
        authorIdentifier,
        authorType,
        conversationBetween,
      );
    }

    setEventSource(idExpert: string, idCustomer: string) {
      this.eventSource = new EventSource(
        conversationHubResource.getEventUrl({
          idCustomer,
          idExpert,
        }),
        { withCredentials: true },
      );
      this.eventSource.onmessage = this.addWithBuffer.bind(this);
    }
  };
