interface Error {
  code: number;
  message: string;
}

interface HydraError {
  'hydra:description': string;
}

export class APIError {
  public code: number;
  public message: string = '';

  constructor(code: number, error: Error | HydraError) {
    this.code = code;
    this.message =
      (error as Error).message || (error as HydraError)['hydra:description'];
  }
}
