import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
// Removed Facebook for now import FacebookLogin from '../components/authentication/FacebookLogin';
import BasicLogin from '../components/authentication/BasicLogin';
import { Loading } from '../components/layout';
import { TranslationContext, UserContext } from '../contexts';

export const LoginPage: React.FC = () => {
  const { isAuthenticated, isExpert } = useContext(UserContext);
  const { translate } = useContext(TranslationContext);
  const history = useHistory();
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isAuthenticated) {
      history.push(isExpert ? '/expert/dashboard' : '/customer/experts');
    }
  }, [isAuthenticated, isExpert, history]);

  // on unmount we disable loading whatever happens
  useEffect(() => () => setLoading(false), []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton color="primary" defaultHref="/home" />
          </IonButtons>
          <IonTitle color="primary">{translate('login.login')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <Loading isLoading={isLoading} />
        <BasicLogin setLoading={setLoading} />
        {/*<FacebookLogin setLoading={setLoading}  />*/}
      </IonContent>
    </IonPage>
  );
};
