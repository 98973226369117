import { authenticationHelper } from '../helpers';
import { APIError } from './APIError';
import { Collection } from './Collection';
import Resource, { ResourceOptions } from './Resource';

abstract class ApiResource<T = {}> extends Resource {
  public baseURL?: string = process.env.REACT_APP_API_ENTRYPOINT;

  static getHeaders(options: ResourceOptions): RequestInit {
    const userInfos = authenticationHelper.getInfos();
    const defaultHeaders: Record<string, string> = {
      accept: 'application/ld+json',
      'content-type': 'application/ld+json',
    };

    if (!!userInfos) {
      defaultHeaders.authorization = `Bearer ${userInfos.token}`;
    }

    return Resource.getHeaders({
      ...options,
      headers: {
        ...defaultHeaders,
        ...options.headers,
      },
    });
  }

  async create(body: object | null, options: ResourceOptions = {}): Promise<T> {
    const response = await fetch(
      this.getUrl(options.endpoint),
      ApiResource.getHeaders({
        method: options.method || 'POST',
        body: JSON.stringify(body),
      }),
    );

    return this.getResponseOrError(response);
  }

  async find(
    id: string,
    params?: any,
    options: ResourceOptions = {},
  ): Promise<T | null> {
    const response = await fetch(
      this.getUrl(id || options.endpoint, params),
      ApiResource.getHeaders(options),
    );

    return this.getResponseOrError(response);
  }

  async findAll(
    params?: any,
    options: ResourceOptions = {},
  ): Promise<Collection<T>> {
    const response = await fetch(
      this.getUrl(options.endpoint, params),
      ApiResource.getHeaders(options),
    );

    return new Collection(await this.getResponseOrError(response));
  }

  async getResponseOrError(response: Response): Promise<T> {
    if (!response.ok) {
      if (response.status === 401 && !response.url.endsWith('/login')) {
        window.location.href = '/logout';
      }
      throw new APIError(response.status, await response.json());
    }

    return response.json();
  }

  async update(
    id: string,
    body: object | null,
    options: ResourceOptions = {},
  ): Promise<T> {
    const response = await fetch(
      this.getUrl(id || options.endpoint),
      ApiResource.getHeaders({
        method: options.method || 'PUT',
        body: JSON.stringify(body),
      }),
    );

    return this.getResponseOrError(response);
  }
}

export default ApiResource;
