import ReactGA from 'react-ga4';
const isTrackingActive = process.env.REACT_APP_ENV === 'prod';

export const webAnalyticsHelper = {
  logEvent: (name: string, parameters: object) => {
    if (isTrackingActive) {
      ReactGA.event(name, parameters);
    }
  },

  setUser: (userId: string) => {
    if (isTrackingActive) {
      ReactGA.set({ user: userId });
    }
  },
};
