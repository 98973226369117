import { Message, MessageType, TopicInfos } from '../models';

const CONVERSATIONS_FIRST = 'conversations-first';
const CONVERSATIONS_READ = 'conversations-read';

interface Topics {
  [key: string]: string;
}

export const conversationHelper = {
  getInfosFromTopic: (topic: string): TopicInfos | null => {
    const matches = topic.match(new RegExp(`/conversations/(\\d+)/(\\d+)`));

    return !!matches
      ? {
          idCustomer: matches[2],
          idExpert: matches[1],
        }
      : null;
  },

  getFirstMessageId: (topic: string): string => {
    return conversationHelper.getTopicInfosFromStorage(CONVERSATIONS_FIRST)[
      topic
    ];
  },

  getLastId: (topic: string): string => {
    return conversationHelper.getTopicInfosFromStorage(CONVERSATIONS_READ)[
      topic
    ];
  },

  getLastOfType: (
    messages: Message[],
    type: MessageType[],
    name?: string,
  ): number => {
    const index = -1;
    let i = messages.length;

    while (i-- > 0) {
      const message = messages[i];
      if (type.includes(message.type) && (!name || name === message.author)) {
        return i;
      }
    }

    return index;
  },

  getTopicInfosFromStorage: (name: string): Topics => {
    const topics = localStorage.getItem(name);

    return !!topics ? JSON.parse(topics) : {};
  },

  setFirstMessageId: (topic: string, id: string): void => {
    const topics = conversationHelper.getTopicInfosFromStorage(
      CONVERSATIONS_FIRST,
    );

    localStorage.setItem(
      CONVERSATIONS_FIRST,
      JSON.stringify({
        ...topics,
        [topic]: id,
      }),
    );
  },

  setLastId: (topic: string, id: string): void => {
    const topics = conversationHelper.getTopicInfosFromStorage(
      CONVERSATIONS_READ,
    );

    localStorage.setItem(
      CONVERSATIONS_READ,
      JSON.stringify({
        ...topics,
        [topic]: id,
      }),
    );
  },
};
