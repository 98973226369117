import { IonContent } from '@ionic/react';
import React, { useContext } from 'react';
import { UserContext } from '../../contexts/';
import { resourceHelper } from '../../helpers';
import { expertResource } from '../../resource';
import { ConversationList, ExpertHeader } from './';
import { ExpertMenu } from './ExpertMenu';
import './expertSidebar.scss';

export const ExpertSidebar: React.FC = () => {
  const { user } = useContext(UserContext);
  if (!user) {
    return null;
  }

  return (
    <IonContent class="conversation-lists-container">
      <ExpertHeader />
      <ConversationList
        idCustomer="{id}"
        idExpert={resourceHelper.getIdFromIri(user['@id'], expertResource)}
        character={null}
      />
      <ExpertMenu />
    </IonContent>
  );
};
