import { RefresherEventDetail } from '@ionic/core';
import {
  IonCard,
  IonCardContent,
  IonItem,
  IonList,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/react';
import React, {
  Fragment,
  MouseEvent as ReactMouseEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { resourceHelper } from '../../helpers';
import { Expert } from '../../models';
import { expertResource } from '../../resource';
import { Loading } from '../layout';
import { ExpertItem } from './ExpertItem';

export const ExpertList: React.FC = () => {
  const [experts, setExperts] = useState<Expert[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const { url } = useRouteMatch();
  const { pathname } = useLocation();

  const gotoExpertDetailsView = (
    e: ReactMouseEvent<HTMLIonCardElement, MouseEvent>,
    iri: string,
  ) => {
    history.push(`${url}/${resourceHelper.getIdFromIri(iri, expertResource)}`);
  };

  const refresh = useCallback(
    async (event?: CustomEvent<RefresherEventDetail>) => {
      setLoading(true);
      const response = await expertResource.findAll();
      setExperts(response.members);
      setLoading(false);

      if (event) {
        event.detail.complete();
      }
    },
    [],
  );

  useEffect(() => {
    if (pathname === '/customer/experts') {
      refresh();
    }
  }, [refresh, pathname]);

  return (
    <Fragment>
      <IonRefresher slot="fixed" onIonRefresh={refresh}>
        <IonRefresherContent />
      </IonRefresher>
      <IonList className="ion-no-padding">
        <Loading isLoading={isLoading} />
        {experts &&
          experts.map(expert => (
            <IonItem key={expert['@id']}>
              <IonCard
                onClick={e => gotoExpertDetailsView(e, expert['@id'])}
                className="ion-text-wrap ion-margin-bottom"
              >
                <IonCardContent>
                  <ExpertItem clamped expert={expert} />
                </IonCardContent>
              </IonCard>
            </IonItem>
          ))}
      </IonList>
    </Fragment>
  );
};
