import React, { Fragment } from 'react';
import { withConversation } from '../../hoc';
import { Character, Message, MessageType } from '../../models';
import { Chat } from './Chat';
import { ChatHeader } from './ChatHeader';

interface Props {
  character: Character | null;
  discussion: Message[];
  send: (
    conversation: string,
    message: string,
    type?: MessageType,
    author?: string,
  ) => void;
}

const ConversationContentView: React.FC<Props> = ({
  character,
  discussion,
  send,
}) => (
  <Fragment>
    <ChatHeader send={send} character={character} />
    <Chat discussion={discussion} send={send} />
  </Fragment>
);

export const ConversationContent = withConversation(ConversationContentView);
