import { MessageType } from '../models';
import Resource from './Resource';

abstract class MercureResource<T> extends Resource {
  public baseURL?: string = process.env.REACT_APP_MERCURE_URL;
  public topicUrl: string = '';

  getMessage(
    id: string,
    topic: string,
    conversation: string,
    type: MessageType,
    message: string,
    conversationBetween: object,
    author?: string,
    authorIdentifier?: string,
    authorType?: string,
  ): FormData {
    const source = {
      topic,
      data: JSON.stringify({
        '@id': id,
        author,
        authorIdentifier,
        authorType,
        conversation,
        conversationBetween,
        date: new Date().toISOString(),
        message,
        type,
      }),
    };
    const data = new URLSearchParams();
    Object.entries(source).forEach(([key, value]) => data.append(key, value));

    return data;
  }

  /**
   * @param replacement pair of key:value to replace topic's infos in the endpoint
   */
  getTopic(replacement: any): string {
    let result = this.topicUrl;

    (Object.keys(replacement) as (keyof T)[]).forEach(key => {
      result = result.replace(new RegExp(`:${key}`, 'g'), replacement[key]);
    });

    return result;
  }

  getTopicUrl(replacement: any): string {
    return this.getUrl() + this.getTopic(replacement);
  }

  async sendMessage(
    id: string,
    topicInfos: T,
    conversation: string,
    type: MessageType,
    message: string,
    author?: string,
    authorIdentifier?: string,
    authorType?: string,
    conversationBetween?: object,
  ): Promise<string> {
    if (conversationBetween === undefined) {
      conversationBetween = [];
    }
    const response = await fetch(
      `${this.getUrl()}`,
      Resource.getHeaders({
        method: 'POST',
        body: this.getMessage(
          id,
          this.getTopicUrl(topicInfos),
          conversation,
          type,
          message,
          conversationBetween,
          author,
          authorIdentifier,
          authorType,
        ),
      }),
    );

    return response.text();
  }
}

export default MercureResource;
