import { IonLoading } from '@ionic/react';
import React, { useContext } from 'react';
import { TranslationContext } from '../../contexts';

interface Props {
  isLoading: boolean;
}

export const Loading = ({ isLoading }: Props) => {
  const { translate } = useContext(TranslationContext);

  return (
    <IonLoading
      isOpen={isLoading}
      message={translate('ui.loading')}
      duration={30000}
    />
  );
};
