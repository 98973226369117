import { IonItem, IonItemDivider, IonLabel, IonNote } from '@ionic/react';
import React, { useContext } from 'react';
import { TranslationContext } from '../../contexts';
import { RfmValues } from '../../models';

interface Props {
  rfmValues: RfmValues;
}

export const RfmScoreList: React.FC<Props> = ({ rfmValues }) => {
  const { translate } = useContext(TranslationContext);

  return (
    <div>
      <IonItemDivider className={'ion-padding-start ion-padding-top'}>
        {translate('metadata.rfm')}
      </IonItemDivider>
      <IonItem>
        <div>
          <IonLabel className={'ion-text-wrap text-sm'} color={'dark'}>
            r: {rfmValues.r} / f: {rfmValues.f} / m: {rfmValues.m}
          </IonLabel>
          <IonNote className={'ion-text-wrap text-sm'} color={'dark'}>
            {translate('metadata.rfmTotalScore')}= {rfmValues.total}
          </IonNote>
        </div>
      </IonItem>
    </div>
  );
};
