import { IonContent, IonPage } from '@ionic/react';
import React, { useContext } from 'react';
import { ExpertList } from '../components/experts';
import { MenuBar } from '../components/layout';
import { TranslationContext } from '../contexts';
import './expertsPage.scss';

export const ExpertsPage: React.FC = () => {
  const { translate } = useContext(TranslationContext);

  return (
    <IonPage>
      <MenuBar title={translate('experts.title')} />
      <IonContent className="expert-list">
        <ExpertList />
      </IonContent>
    </IonPage>
  );
};
