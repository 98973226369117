import React, { useContext, useEffect } from 'react';
import { UserContext } from '../../contexts';

export const NotificationRequest: React.FC = () => {
  const { isExpert } = useContext(UserContext);
  useEffect(() => {
    if (
      isExpert &&
      Notification &&
      Notification.permission !== 'granted' &&
      Notification.requestPermission
    ) {
      Notification.requestPermission();
    }
  }, [isExpert]);

  return null;
};
