import { Plugins } from '@capacitor/core';
import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React from 'react';
import { NotificationRequest } from './components/expert/NotificationRequest';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';

/* Theme variables */
import { Routes } from './components';
import { Device, ErrorHandler, Payment, Translation, User } from './contexts';
import './theme/variables.scss';
const { SplashScreen } = Plugins;

const App: React.FC = () => {
  SplashScreen.hide();

  return (
    <IonApp>
      <IonReactRouter>
        <ErrorHandler>
          <Device>
            <Translation>
              <User>
                <NotificationRequest />
                <Payment>
                  <Routes />
                </Payment>
              </User>
            </Translation>
          </Device>
        </ErrorHandler>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
