import { IonLabel } from '@ionic/react';
import React from 'react';
import './metadata.scss';

interface Props {
  message: string;
}

export const ErrorMessage = ({ message }: Props) => (
  <IonLabel color={'danger'} className={'centered ion-padding-top'}>
    {message}
  </IonLabel>
);
