export interface ResourceOptions extends RequestInit {
  endpoint?: string;
}

abstract class Resource {
  public baseURL?: string = '';
  public endpoint: string = '';

  static getHeaders(options: ResourceOptions): RequestInit {
    return {
      body: options.body,
      credentials: 'include',
      method: options.method || 'GET',
      headers: new Headers({ ...options.headers }),
    };
  }

  getUrl(endpoint?: string, params?: any): string {
    const encodedParams = params
      ? `?${new URLSearchParams(params).toString()}`
      : '';

    return this.baseURL + (endpoint || this.endpoint) + encodedParams;
  }
}

export default Resource;
