import { IonContent, IonPage } from '@ionic/react';
import { IonSlide, IonSlides } from '@ionic/react';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import DeviceLogin from '../components/authentication/DeviceLogin';
import { TranslationContext, UserContext } from '../contexts';
import './homepage.scss';

const slideOpts = {
  initialSlide: 0,
  speed: 400,
};

export const HomePage: React.FC = () => {
  const history = useHistory();
  const { isAuthenticated, isExpert } = useContext(UserContext);
  const { translate } = useContext(TranslationContext);

  useEffect(() => {
    if (isAuthenticated) {
      history.push(isExpert ? '/expert/dashboard' : '/customer/experts');
    }
  }, [isAuthenticated, isExpert, history]);

  return (
    <IonPage>
      <IonContent fullscreen scrollY={false}>
        <IonSlides pager={true} options={slideOpts} mode="ios">
          <IonSlide className="small">
            <h2>{translate('home.slides.1.title')}</h2>
            <img src="/assets/img/tutorial/1_reading.png" alt="reading" />
            <p>{translate('home.slides.1.p1')}</p>
            <p>{translate('home.slides.1.p2')}</p>
            <p>
              <span>{translate('home.slides.1.p3Span')}</span>
            </p>

            <DeviceLogin
              text={translate('home.buttons.chatNow')}
              redirect={'/customer/experts'}
            />
          </IonSlide>

          <IonSlide className="small">
            <h2>{translate('home.slides.2.title')}</h2>
            <img src="/assets/img/tutorial/2_best_psychics.png" alt="chat" />
            <p>{translate('home.slides.2.p1')}</p>
            <p>
              <span>{translate('home.slides.2.p2Span')}</span>
            </p>
            <DeviceLogin
              text={translate('home.buttons.chatNow')}
              redirect={'/customer/experts'}
            />
          </IonSlide>

          <IonSlide className="small">
            <h2>{translate('home.slides.3.title')}</h2>
            <img src="/assets/img/tutorial/3_chat.png" alt="chat" />
            <ol>
              <li>{translate('home.slides.3.ol.1')}</li>
              <li>{translate('home.slides.3.ol.2')}</li>
              <li>{translate('home.slides.3.ol.3')}</li>
            </ol>
            <DeviceLogin
              text={translate('home.buttons.getStarted')}
              redirect={'/customer/experts'}
            />
          </IonSlide>
        </IonSlides>
      </IonContent>
    </IonPage>
  );
};
