import { IonSkeletonText } from '@ionic/react';
import React from 'react';
import './metadataPlaceholder.scss';

export const MetadataPlaceholder = () => (
  <div className={'ion-padding skeleton'}>
    <IonSkeletonText animated />
    <IonSkeletonText animated style={{ width: '80%' }} />
    <IonSkeletonText animated style={{ width: '60%' }} />
    <IonSkeletonText animated style={{ width: '30%' }} />
  </div>
);
