import {
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonLoading,
  IonNote,
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { DATE_FORMAT } from '../../constants';
import { TranslationContext, UserContext } from '../../contexts';
import { resourceHelper } from '../../helpers';
import { Conversation } from '../../models';
import {
  conversationResource,
  customerResource,
  expertResource,
} from '../../resource';
import { Time } from '../icons';
import './conversationList.scss';

interface Props {
  conversationPrefix?: string;
  displayHeader?: boolean;
  headerText?: string;
}

export const ConversationList: React.FC<Props> = ({
  conversationPrefix = '',
  displayHeader = false,
  headerText = 'menu.conversations.pastConversations',
}) => {
  const { isCustomer } = useContext(UserContext);
  const { translate, translateDate } = useContext(TranslationContext);
  const history = useHistory();
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { pathname } = useLocation();

  useEffect(() => {
    const doFetch = async () => {
      const response = await conversationResource.findAll();
      setLoading(false);
      setConversations(response.members);
    };

    if (['/customer/past-conversations'].includes(pathname)) {
      setLoading(true);
      doFetch();
    }
    // eslint-disable-next-line
  }, [pathname]);

  if (loading) {
    return <IonLoading isOpen message={translate('ui.loading')} />;
  }

  if (!conversations.length) {
    return null;
  }

  const joinConversation = ({ customer, expert, '@id': id }: Conversation) => {
    const idCustomer = resourceHelper.getIdFromIri(
      customer['@id'],
      customerResource,
    );
    const idExpert = resourceHelper.getIdFromIri(expert['@id'], expertResource);
    history.push(
      `${conversationPrefix}/conversation/${idExpert}/${idCustomer}`,
      {
        idConversation: id,
        readOnly: true,
      },
    );
  };

  // TODO Handle infinite scroll for large amount of conversations
  return (
    <IonItemGroup>
      {displayHeader && (
        <IonItemDivider className="sticky sticky-after-active">
          {translate(headerText)}
        </IonItemDivider>
      )}
      {conversations.map(conversation => {
        return (
          <IonItem
            className="conversation-item"
            key={conversation['@id']}
            lines="none"
            onClick={() => joinConversation(conversation)}
            detail
          >
            <div>
              <IonLabel>
                {isCustomer
                  ? conversation.expert.name
                  : conversation.customer.name}
              </IonLabel>
              {conversation.updatedAt ? (
                <IonNote>
                  <Time />{' '}
                  {translateDate(conversation.updatedAt, DATE_FORMAT.FULL)}
                </IonNote>
              ) : null}
            </div>
          </IonItem>
        );
      })}
    </IonItemGroup>
  );
};
