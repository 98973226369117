export const en = {
  authentication: {
    email: 'Email',
    error: 'Incorrect login or password',
    password: 'Password',
    signinButton: 'Sign-in',
    signinDeviceButton: 'Chat without registration',
    signinFBButton: 'Login with Facebook',
  },
  chat: {
    availableCredit: 'Customer refilled his wallet.',
    bot: {
      activate: 'Activate chatbot',
      deactivate: 'Deactivate chatbot',
    },
    creditPendingCustomer:
      'You credit is low, you have one minute to refill your account and avoid interruption',
    creditPendingExpert: 'Waiting for your payment',
    join: '{{name}} joined the chat',
    leave: '{{name}} left the chat',
    resume: '{{name}} resumed the chat',
    stopped: 'Conversation has been stopped',
    topUp: 'You have to purchase more credits to continue your conversation',
    target: 'In chat with',
    write: 'Write a message...',
    you: 'You',
    addCredits: 'Add credits',
    resumeConversation: 'Resume conversation',
    finishReadingConfirmation:
      'You are leaving the conversation, do you want to continue the chat?',
    expertAvailable:
      'If you leave now your advisor will become available for someone else, are you sure?',
    resumeReading: 'Yes, continue chat',
    finishReading: 'No, end chat',
    expertFinishReading: 'Finish reading',
    expertOnline: 'Your advisor is online right now !',
    expertOffline: 'Your advisor is offline for the moment',
    welcomeMessage:
      'Conversation will start shortly. Waiting for {{name}} to join... please be patient and focus on your question',
  },
  currency: ' {{amount}}',
  dashboard: {
    activeConversations: 'Active conversations',
    conversationWith: 'Conversation with {{name}}',
    join: 'Join',
  },
  expert: {
    title: 'Advisor',
    category: {
      none: 'Uncategorized',
      separator: ' & ',
    },
    description: 'Advisor description',
    startChat: 'Start chat',
    startConversation: 'Start a conversation',
    status: {
      offline: 'Offline',
      online: 'Online',
    },
    notificationTitle: 'Message from {{name}}',
  },
  experts: {
    title: 'Advisors',
  },
  character: {
    startChatting: 'Are you ready to start chatting with {{character}}?',
    communicationPackage: 'Your communication package is ready to use',
  },
  home: {
    slides: {
      1: {
        title:
          'How To Get Real-Time Answers To Your Questions 24/7 From Real Psychics?',
        p1: 'AstroConnect is a text-based chat mobile app.',
        p2:
          'Get answers from the best Astrologers, Tarot card readers, and numerologists today!',
        p3Span: 'The first 3-minute consultation is 100% FREE',
      },
      2: {
        title: 'Only The Best And Most Accurate Professionals For You',
        p1:
          'Directly from the app, select your favorite Psychics and start chatting',
        p2Span: 'First 3 minutes are FREE',
      },
      3: {
        title: 'How does it work?',
        ol: {
          1: 'Pick your Psychic from the list',
          2: 'Write your question',
          3: 'Get your answer',
        },
      },
    },
    buttons: {
      chatNow: 'START NOW',
      getStarted: 'Yes, I want to try for FREE',
    },
  },
  global: {
    serverError: 'Error communicating with server.',
    appStoreConnectionError: 'Cannot connect to store, please try again.',
    paymentError:
      'An error occurred during the payment. Please try again later.',
    deviceIdError: 'This device is already linked to another account',
  },
  login: {
    login: 'Login',
  },
  menu: {
    conversations: {
      activeConversations: 'Active conversations',
      pastConversations: 'Past conversations',
      empty: 'no conversation',
    },
    header: 'astroconnect',
    links: {
      chats: 'Chats',
      experts: 'Advisors',
      logout: 'Logout',
      notifications: 'Notifications',
      settings: 'Settings',
      about: 'About',
      horoscopes: 'Horoscopes',
      help: 'Customer Support',
      howItWorks: 'How it works',
    },
  },
  metadata: {
    chatscript: {
      actions_label: 'Chatscript actions',
    },
    header: '{{ name }} metadata',
    noPurchase: 'No purchase made',
    noRefund: 'No refund made',
    purchasedItemsHistory: 'Purchase history',
    refundItemsHistory: 'Refund history',
    rfm: 'RFM',
    rfmTotalScore: 'TOTAL scoring',
    status: 'Status',
    totalPrice: 'Total credits bought',
    totalPurchases: 'Number of purchase(s)',
    totalRefund: 'Refund total amount',
    totalRefundItems: 'Number of refund(s)',
  },
  package: {
    welcomeGift: {
      freeMinutes: {
        title: 'Free reading',
        header: {
          1: 'Your first',
          2: '3 minutes 100% FREE',
          3: '',
        },
        body: 'Select your Advisor and start your chat now.',
        okButton: 'Get My Free Reading',
      },
    },
  },
  payment: {
    title: 'Payment',
    popup: {
      title: 'No more credit',
      myBalance: 'My balance : ',
      cancel: 'Cancel purchase',
      close: 'Close',
      currencyFooter: 'All prices are in USD $',
      notAvailable: 'Please note you can only recharge on your smartphone.',
      textNoCredit:
        'Your session is on pause now. To continue the session with the advisor you need to add more funds',
      textAddCredit: 'You can quickly add credit if you need some more:',
      textInfo: 'Please add credits to your balance before continue chat',
      textLink: 'Click here to buy credits',
    },
  },
  ui: {
    loading: 'Loading...',
  },
};
