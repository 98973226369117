import dayjs from 'dayjs';
import i18next from 'i18next';
import React, {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { DATE_FORMAT } from '../constants';
import { en } from '../i18n';

export type Translate = (key: string, context?: object) => string;

interface TranslationContextModel {
  translate: Translate;
  translateDate: (key: string, format?: string) => string;
}

export const TranslationContext = createContext<TranslationContextModel>({
  translate: () => '',
  translateDate: () => '',
});

interface Props {
  children: ReactNode;
}

export const Translation: FC<Props> = ({ children }) => {
  const [ready, setReady] = useState<boolean>(false);

  useEffect(() => {
    const init = async () => {
      await i18next.init({
        lng: 'en',
        resources: {
          en: {
            translation: en,
          },
        },
      });
      setReady(true);
    };

    init();
  }, []);

  const translateDate = (value: string, format?: string) => {
    if (format && Object.values(DATE_FORMAT).includes(format)) {
      return dayjs(value)
        .format(format)
        .toString();
    }

    return dayjs(value).toISOString();
  };

  return ready ? (
    <TranslationContext.Provider
      value={{ translate: i18next.t.bind(i18next), translateDate }}
    >
      {children}
    </TranslationContext.Provider>
  ) : null;
};
