import ApiResource from '../resource/ApiResource';

export const resourceHelper = {
  getIdFromIri: (iri: string, resource: ApiResource): string => {
    const matches = iri.match(
      new RegExp(`${resource.endpoint}/(['a-zA-Z0-9]*)`),
    );

    return !!matches ? matches[1] : '';
  },

  getIriFromIdAndResource: (id: string, resource: ApiResource): string => {
    return `${resource.endpoint}/${id}`;
  },
};
