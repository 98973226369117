import { conversationHelper } from '../helpers';
import { TopicInfos } from '../models';
import MercureResource from './MercureResource';

class ConversationHubResource<T> extends MercureResource<T> {
  public topicUrl: string = '/conversations/:idExpert/:idCustomer';

  getEventUrl(topicInfos: T): string {
    return this.getUrl('', {
      topic: this.getTopicUrl(topicInfos),
      lastEventID: conversationHelper.getFirstMessageId(
        this.getTopic(topicInfos),
      ),
    });
  }
}

export const conversationHubResource = new ConversationHubResource<
  TopicInfos
>();
