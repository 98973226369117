import {
  IonButton,
  IonButtons,
  IonFabButton,
  IonHeader,
  IonModal,
  IonToolbar,
} from '@ionic/react';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { CONVERSATION_STATUS } from '../../constants';
import {
  ConversationContext,
  TranslationContext,
  UserContext,
} from '../../contexts';
import {
  AuthorType,
  Category,
  Character,
  Customer,
  Expert,
  MessageType,
} from '../../models';
import { conversationResource } from '../../resource';
import { Avatar, CharacterAvatar } from '../global';
import { Back } from '../icons';
import { BalanceInformation } from './BalanceInformation';

interface Props {
  character: Character | null;
  send: (
    conversation: string,
    message: string,
    type?: MessageType,
    author?: string,
    authorIdentifier?: string,
    authorType?: string,
    conversationBetween?: object,
  ) => void;
}

export const ChatHeader: React.FC<Props> = ({ character, send }) => {
  const {
    conversation,
    idConversation,
    target,
    status,
    setStatus,
    setConversation,
  } = useContext(ConversationContext);
  const { translate } = useContext(TranslationContext);
  const { isExpert, isCustomer, user } = useContext(UserContext);
  const [showGoBackConfirmation, setShowGoBackConfirmation] = useState(false);
  const history = useHistory();
  const expert = target as Expert;

  if (!target || !conversation) {
    return null;
  }

  const sendLeaveMessage = async () => {
    if (user && isCustomer) {
      send(
        idConversation,
        translate('chat.leave', { name: user.name }),
        MessageType.LEAVE,
        user.name,
        user.authorIdentifier,
        AuthorType.SYSTEM,
        [user.authorIdentifier, expert.authorIdentifier],
      );
    }
  };
  const goBack = () => {
    if (isExpert) {
      history.push('/expert/dashboard');
    } else {
      if (status === CONVERSATION_STATUS.STOP) {
        finishReading();
      } else {
        setShowGoBackConfirmation(true);
      }
    }
  };

  const confirmGoBack = () => {
    setShowGoBackConfirmation(false);
    finishReading();
  };

  const finishReading = () => {
    const finishConversation = async () =>
      setConversation(await conversationResource.finish(idConversation));

    /* eslint-disable no-console */
    finishConversation()
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        sendLeaveMessage().finally(() => {
          setStatus(CONVERSATION_STATUS.STOP);
          if (isCustomer) {
            if (conversation != null && character !== null) {
              history.push('/character/' + character.shortCode);
            } else {
              history.push('/customer/experts');
            }
          } else {
            history.push('/expert/dashboard');
          }
        });
      });
    /* eslint-enable no-console */
  };

  const getCategoriesNames = () => {
    if (
      target &&
      (target as Expert).categories &&
      (target as Expert).categories.length
    ) {
      return (target as Expert).categories
        .map((category: Category) => category.name)
        .join(translate('expert.category.separator'));
    }

    return '';
  };

  return (
    <IonHeader data-testid="chat-header-content">
      <IonToolbar className="toolbar-item">
        <IonButtons slot="start">
          <IonFabButton
            onClick={goBack}
            routerDirection="back"
            data-testid="back"
          >
            <Back />
          </IonFabButton>
        </IonButtons>
        <IonButtons slot="end" className="user-info" data-testid="target-info">
          <div className="header-meta">
            <div className="header-mood">{translate('chat.target')}</div>
            <div className="header-name">
              {isExpert && status && status !== CONVERSATION_STATUS.STOP ? (
                <IonButton
                  onClick={finishReading}
                  fill="solid"
                  data-testid="finish-conversation"
                >
                  {translate('chat.expertFinishReading')}
                </IonButton>
              ) : null}
              {isCustomer ? (
                <span className="header-expert-category">
                  {getCategoriesNames()}
                </span>
              ) : null}
              {isExpert
                ? target.name
                : character !== null
                ? character.name
                : ''}
            </div>
          </div>
          <div className="header-image">
            {isExpert ? (
              <Avatar user={null} />
            ) : (
              <CharacterAvatar user={character} />
            )}
          </div>
        </IonButtons>
      </IonToolbar>
      {isExpert ? (
        <BalanceInformation customer={target as Customer} status={status} />
      ) : null}
      <IonModal
        isOpen={showGoBackConfirmation}
        cssClass="confirmation-modal"
        backdropDismiss={false}
      >
        <div className="container" data-testid="confirmation-modal">
          <p>{translate('chat.finishReadingConfirmation')}</p>
          <p>{translate('chat.expertAvailable')}</p>
          <div className="controls">
            <IonButton onClick={() => setShowGoBackConfirmation(false)}>
              {translate('chat.resumeReading')}
            </IonButton>
            <IonButton onClick={confirmGoBack}>
              {translate('chat.finishReading')}
            </IonButton>
          </div>
        </div>
      </IonModal>
    </IonHeader>
  );
};
