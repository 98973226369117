import { Authentication } from '../models';

const USER = 'user';
const STORAGE_DEVICE_ID_USED_KEY = 'device_id_used_for_auth';
const STORAGE_EMAIL_USED_KEY = 'email_used_for_auth';

export const authenticationHelper = {
  isAuthenticated: () => {
    return !!localStorage.getItem(USER);
  },

  getInfos: (): Authentication | null => {
    const userInfos = localStorage.getItem(USER);

    return userInfos ? JSON.parse(userInfos) : null;
  },

  setInfos: (infos: Authentication) => {
    localStorage.setItem(USER, JSON.stringify(infos));
  },

  isDeviceIdUsed: () => {
    return !!localStorage.getItem(STORAGE_DEVICE_ID_USED_KEY);
  },

  setDeviceIdUsed: () => {
    localStorage.setItem(STORAGE_DEVICE_ID_USED_KEY, '1');
  },

  unsetDeviceIdUsed: () => {
    localStorage.removeItem(STORAGE_DEVICE_ID_USED_KEY);
  },

  removeInfos: () => {
    localStorage.removeItem(USER);
    authenticationHelper.unsetDeviceIdUsed();
  },
};
