import { IonContent, IonRouterLink } from '@ionic/react';
import React, { useContext } from 'react';
import { TranslationContext, UserContext } from '../../contexts';
import { Customer } from '../../models';

interface Props {
  onClose: () => void;
}

export const ExternalPayment: React.FC<Props> = ({ onClose }) => {
  const { translate } = useContext(TranslationContext);
  const { user } = useContext(UserContext);
  const rechargeLink = (user as Customer).rechargeLink;

  return (
    <IonContent className="payment-container">
      <h2>{translate('payment.popup.title')}</h2>
      <p>{translate('payment.popup.textInfo')}</p>
      <p className="cancel-button">
        <IonRouterLink href={rechargeLink}>
          {translate('payment.popup.textLink')}
        </IonRouterLink>
      </p>
    </IonContent>
  );
};
