import { IonCol, IonContent, IonItem, IonRow } from '@ionic/react';
import React, { Fragment, useContext } from 'react';
import { TranslationContext } from '../../contexts';
import { Character } from '../../models';
import { StartChatWithCharacterButton } from '../conversations/StartChatWithCharacterButton';
import { CharacterAvatar } from '../global';
import './characterItem.scss';

interface Props {
  character: Character;
  clamped?: boolean;
}

export const CharacterItem: React.FC<Props> = ({ clamped, character }) => {
  const { translate } = useContext(TranslationContext);

  const { shortCode, name } = character;

  return (
    <Fragment>
      <IonContent class="character-content">
        <IonRow>
          <IonCol size="12">
            <IonRow>
              <IonCol size="12">
                <IonItem>
                  <p>{translate('character.communicationPackage')}</p>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12">
                <IonItem>
                  <p>
                    {translate('character.startChatting', { character: name })}
                  </p>
                </IonItem>
                <IonCol size="12">
                  <IonRow>
                    <IonCol size="6">
                      <CharacterAvatar user={character as Character} />
                    </IonCol>
                    <IonCol size="6">{character.summary}</IonCol>
                  </IonRow>
                </IonCol>
                <StartChatWithCharacterButton
                  text={translate('expert.startChat')}
                  shortCode={shortCode}
                />
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonContent>
    </Fragment>
  );
};
