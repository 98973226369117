import { IonIcon } from '@ionic/react';
import {
  arrowBack,
  chatbubbles,
  play,
  refresh,
  star,
  starHalf,
  starOutline,
  time,
  wallet,
} from 'ionicons/icons';
import React from 'react';

interface Icon {
  icon: {
    ios: string;
    md: string;
  };
}

const Icon = ({ icon }: Icon) => <IonIcon {...{ icon }} />;

export const Back = (props: any) => <IonIcon icon={arrowBack} {...props} />;
export const ChatBubbles = (props: any) => (
  <IonIcon icon={chatbubbles} {...props} />
);
export const EmptyStar = (props: any) => (
  <IonIcon icon={starOutline} {...props} />
);
export const HalfStar = (props: any) => <IonIcon icon={starHalf} {...props} />;
export const Play = (props: any) => <IonIcon icon={play} {...props} />;
export const Star = (props: any) => <IonIcon icon={star} {...props} />;
export const Time = (props: any) => <IonIcon icon={time} {...props} />;
export const Wallet = (props: any) => <IonIcon icon={wallet} {...props} />;
export const Refresh = (props: any) => <IonIcon icon={refresh} {...props} />;
