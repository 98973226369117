import { IonPage } from '@ionic/react';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Content } from '../components/content';
import './contentpage.scss';

interface Props
  extends RouteComponentProps<{
    slug: string;
  }> {}

export const ContentPage: React.FC<Props> = ({ match }) => {
  const { slug } = match.params;

  return (
    <IonPage>
      <Content slug={slug} key={slug} />
    </IonPage>
  );
};
