import { IonRouterOutlet } from '@ionic/react';
import React from 'react';
import { Redirect, Route, RouteProps, useRouteMatch } from 'react-router';
import {
  ExpertPage,
  ExpertsPage,
  PastConversationsPage,
} from '../../pages/index';

const getCustomerRoutes = (path: string): RouteProps[] => [
  {
    path: `${path}/past-conversations`,
    render: (props: any) => <PastConversationsPage {...props} />,
  },
  {
    path: `${path}/experts/:id`,
    component: ExpertPage,
  },
  {
    path: `${path}/experts`,
    render: () => <ExpertsPage />,
    exact: true,
  },
  {
    path,
    render: () => <Redirect to={`${path}/experts`} />,
    exact: true,
  },
];

export const CustomerLayout = () => {
  const { path } = useRouteMatch();

  return (
    <IonRouterOutlet>
      {getCustomerRoutes(path).map((route: RouteProps, key: number) => (
        <Route
          {...{
            key,
            ...route,
          }}
        />
      ))}
    </IonRouterOutlet>
  );
};
