import { IonFooter, IonIcon, IonItem, IonLabel } from '@ionic/react';
import { logOut } from 'ionicons/icons';
import React, { useContext } from 'react';
import { TranslationContext } from '../../contexts';

export const ExpertMenu: React.FC = () => {
  const { translate } = useContext(TranslationContext);

  return (
    <IonFooter>
      <IonItem lines="none" routerLink="/logout" class={'dashboard-logout'}>
        <IonIcon slot="start" icon={logOut} />
        <IonLabel>{translate('menu.links.logout')}</IonLabel>
      </IonItem>
    </IonFooter>
  );
};
