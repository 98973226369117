import { IonAvatar } from '@ionic/react';
import React from 'react';
import { Expert } from '../../models';

interface Props {
  className?: string;
  user: Expert | null;
}

export const Avatar = ({ className, user }: Props) => (
  <IonAvatar className={className}>
    {user !== null && user.avatarUrl ? (
      <img src={user.avatarUrl} alt="" />
    ) : (
      <img src="/assets/img/anonymous.jpg" alt="" />
    )}
  </IonAvatar>
);
