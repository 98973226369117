import {
  IonButton,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { TranslationContext } from '../../contexts';
import { Conversation } from '../../models';
import { conversationResource } from '../../resource';
import { chatscriptResource } from '../../resource/chatscriptResource';

interface Props {
  idConversation: string;
}

export const ConversationActions: React.FC<Props> = ({ idConversation }) => {
  const { translate } = useContext(TranslationContext);
  const [conversation, setConversation] = useState<Conversation | null>(null);
  const [chatscriptLabel, setChatscriptLabel] = useState(
    translate('chat.bot.activate'),
  );
  const [isChatscriptActive, setIsChatscriptActive] = useState(false);
  useEffect(() => {
    const doFetch = async () => {
      setConversation(await conversationResource.find(idConversation));
    };

    doFetch().then();
  }, [idConversation]);
  useEffect(() => {
    if (conversation) {
      setChatscriptLabel(
        conversation.chatscriptActive
          ? translate('chat.bot.deactivate')
          : translate('chat.bot.activate'),
      );
      setIsChatscriptActive(conversation.chatscriptActive);
    }
  }, [conversation]);

  const changeChatscriptStatus = () => {
    const updateStatus = async () => {
      isChatscriptActive
        ? await chatscriptResource.deactivate(idConversation)
        : await chatscriptResource.activate(idConversation);
    };
    updateStatus().catch(error => {
      console.error(error);
    });
    setIsChatscriptActive(!isChatscriptActive);
    setChatscriptLabel(
      !isChatscriptActive
        ? translate('chat.bot.deactivate')
        : translate('chat.bot.activate'),
    );
  };

  return (
    <div>
      <IonItemDivider>
        <IonLabel color={'medium'}>
          {translate('metadata.chatscript.actions_label')}
        </IonLabel>
      </IonItemDivider>
      <IonList>
        <IonItem>
          <IonButton
            className={'ion-text-wrap'}
            onClick={changeChatscriptStatus}
            fill="solid"
            size={'default'}
          >
            {chatscriptLabel}
          </IonButton>
        </IonItem>
      </IonList>
    </div>
  );
};
