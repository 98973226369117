import { IonContent, IonPage } from '@ionic/react';
import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import { ExpertShow } from '../components/experts';
import { MenuBar } from '../components/layout';
import { TranslationContext } from '../contexts';

interface Props
  extends RouteComponentProps<{
    id: string;
  }> {}

export const ExpertPage: React.FC<Props> = ({ match }) => {
  const { translate } = useContext(TranslationContext);

  return (
    <IonPage>
      <MenuBar backLink="/customer/expert" title={translate('experts.title')} />
      <IonContent className="ion-padding">
        <ExpertShow id={match.params.id} />
      </IonContent>
    </IonPage>
  );
};
