import { DeviceInfo, Plugins } from '@capacitor/core';
import React, {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';

export interface DeviceContextModel extends DeviceInfo {
  isBrowser: boolean;
}

export const DeviceContext = createContext<DeviceContextModel | null>(null);

interface Props {
  children: ReactNode;
}

export const Device: FC<Props> = ({ children }) => {
  const [info, setInfo] = useState<DeviceInfo>();

  useEffect(() => {
    const init = async () => {
      setInfo(await Plugins.Device.getInfo());
    };

    init();
  }, []);

  const isBrowser = useMemo(() => {
    return info && info.platform === 'web';
  }, [info]);

  return info ? (
    <DeviceContext.Provider value={{ ...info, isBrowser: !!isBrowser }}>
      {children}
    </DeviceContext.Provider>
  ) : null;
};
