import { IonLoading } from '@ionic/react';
import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router';
import { ConversationContent } from '../components/conversations';
import { CONVERSATION_STATUS } from '../constants';
import { conversationHelper, resourceHelper } from '../helpers';
import { Character, Conversation as ConversationModel, User } from '../models';
import {
  characterResource,
  conversationHubResource,
  conversationResource,
  customerResource,
  expertResource,
} from '../resource';
import { UserContext } from './UserContext';

interface ConversationContextModel {
  conversation: ConversationModel | null;
  idConversation: string;
  idCustomer: string;
  idExpert: string;
  readOnly: boolean;
  setConversation: (conversation: ConversationModel | null) => void;
  setStatus: (conversationStatus: string) => void;
  source: User | null;
  status: string;
  target: User | null;
}

export const ConversationContext = createContext<ConversationContextModel>({
  idConversation: '',
  idCustomer: '',
  idExpert: '',
  readOnly: false,
  source: null,
  target: null,
  status: CONVERSATION_STATUS.ACTIVE,
  setStatus: () => null,
  conversation: null,
  setConversation: () => null,
});

interface Props {
  idConversation: string;
  idCustomer: string;
  idExpert: string;
  readOnly?: boolean;
}

export const Conversation: FC<Props> = ({
  idConversation,
  idCustomer,
  idExpert,
  readOnly = false,
}) => {
  const { isExpert, user } = useContext(UserContext);
  const [source, setSource] = useState<User | null>(null);
  const [conversation, setConversation] = useState<ConversationModel | null>(
    null,
  );
  const [target, setTarget] = useState<User | null>(null);
  const [character, setChatCharacter] = useState<Character | null>(null);
  // the setReadOnly is now only used for past conversations and is set on the page side
  const [isConversationReady, setIsConversationReady] = useState<string>('');
  const [status, setStatus] = useState<string>(CONVERSATION_STATUS.ACTIVE);
  const history = useHistory();

  // store first message ID of a conversation
  useEffect(() => {
    if (idConversation) {
      conversationResource
        .find(idConversation)
        .then(response => {
          setConversation(response);
          if (!response) {
            history.push('/expert/dashboard');
            return;
          }

          conversationHelper.setFirstMessageId(
            conversationHubResource.getTopic({
              idCustomer: response.customer.id,
              idExpert: response.expert.id,
            }),
            response.firstMercureMessageId,
          );
          setIsConversationReady(idConversation);
        })
        .catch(() => {
          history.push('/expert/dashboard');
        });
    }
  }, [history, idConversation]);

  // TODO add comment and help here
  useEffect(() => {
    // declare boolean flag to avoid memory leak warning (caused by a state update on component already deleted from DOM)
    let isMounted = true;

    const doFetch = async () => {
      const targetResource = isExpert
        ? await customerResource.find(
            resourceHelper.getIriFromIdAndResource(
              idCustomer,
              customerResource,
            ),
          )
        : await expertResource.find(
            resourceHelper.getIriFromIdAndResource(idExpert, expertResource),
          );
      const chatCharacter =
        conversation !== null
          ? await characterResource.find(
              resourceHelper.getIriFromIdAndResource(
                conversation.character.shortCode,
                characterResource,
              ),
            )
          : null;

      return {
        target: targetResource,
        character: chatCharacter,
      };
    };

    if (!!idExpert && !!idCustomer && !!user) {
      setSource(user);
    }

    doFetch().then(resources => {
      if (isMounted) {
        setTarget(resources.target);
        setChatCharacter(resources.character);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [isExpert, idExpert, idCustomer, user, conversation]);

  // TODO add doc
  useEffect(() => {
    if (conversation && conversation.status) {
      // TODO: Sync backend and frontend statuses
      switch (conversation.status) {
        case 'new':
          setStatus(CONVERSATION_STATUS.ACTIVE);
          break;
        case 'active':
          setStatus(CONVERSATION_STATUS.START);
          break;
        case 'paused':
          setStatus(CONVERSATION_STATUS.CREDIT_PENDING);
          break;
        case 'finished':
          setStatus(CONVERSATION_STATUS.STOP);
          break;
      }
    }
    // eslint-disable-next-line
  }, [conversation]);

  if (!source || !target || idConversation !== isConversationReady) {
    return <IonLoading isOpen={true} message="Please wait..." />;
  }

  return (
    <ConversationContext.Provider
      value={{
        idConversation,
        idCustomer,
        idExpert,
        readOnly,
        source,
        target,
        status,
        setStatus,
        conversation,
        setConversation,
      }}
    >
      <ConversationContent
        idCustomer={idCustomer}
        idExpert={idExpert}
        character={character}
      />
    </ConversationContext.Provider>
  );
};
