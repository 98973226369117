import React, { useEffect, useState } from 'react';
import { resourceHelper } from '../../helpers';
import { Expert as ExpertModel } from '../../models';
import { expertResource } from '../../resource';
import { ExpertItem } from './ExpertItem';

interface Props {
  id: string;
}

export const ExpertShow: React.FC<Props> = ({ id }) => {
  const [expert, setExpert] = useState<ExpertModel | null>(null);

  useEffect(() => {
    const doFetch = async () => {
      setExpert(
        await expertResource.find(
          resourceHelper.getIriFromIdAndResource(id, expertResource),
        ),
      );
    };

    doFetch();
  }, [id]);

  return expert ? <ExpertItem expert={expert} /> : null;
};
