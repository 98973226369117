import { IonCol, IonPage, IonRow } from '@ionic/react';
import React, { useContext } from 'react';
import { RouteComponentProps, useLocation } from 'react-router';
import { CustomerMetadataSidebar } from '../components/metadata';
import { Conversation, UserContext } from '../contexts';
import './conversationPage.scss';

interface Props
  extends RouteComponentProps<{
    idCustomer: string;
    idExpert: string;
  }> {}

export const ConversationPage: React.FC<Props> = ({ match }) => {
  const { idCustomer, idExpert } = match.params;
  const { state } = useLocation();
  const { isExpert } = useContext(UserContext);

  if (!state || !state.idConversation) {
    return null;
  }

  return (
    <IonRow className="expert-layout bla-theme">
      <IonCol sizeMd="3" className="ion-hide-sm-down">
        <img src="/assets/img/bla/i18n/en-US/left-blanche.png" alt={''} />
      </IonCol>
      <IonCol sizeMd={isExpert ? '6' : '7'} className="chat-frame">
        {/* Remove IonPage element from Customer side to avoid ionic bug with ion-page-invisible */}
        {isExpert ? (
          <IonPage>
            <Conversation
              idConversation={state.idConversation}
              idCustomer={idCustomer}
              idExpert={idExpert}
              readOnly={!!state.readOnly}
            />
          </IonPage>
        ) : (
          <div className="ion-page">
            <Conversation
              idConversation={state.idConversation}
              idCustomer={idCustomer}
              idExpert={idExpert}
              readOnly={!!state.readOnly}
            />
          </div>
        )}
      </IonCol>
      {isExpert ? <IonCol sizeMd="1" className="ion-hide-sm-down" /> : null}
      {isExpert ? (
        <IonCol size="2" className="expert-sidebar">
          <CustomerMetadataSidebar
            idCustomer={idCustomer}
            idConversation={state.idConversation}
          />
        </IonCol>
      ) : null}
    </IonRow>
  );
};
