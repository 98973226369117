import { IonCol, IonItem, IonLabel, IonRow, IonText } from '@ionic/react';
import React, { Fragment, useContext } from 'react';
import { TranslationContext } from '../../contexts';
import { Expert } from '../../models';
import { StartChatButton } from '../conversations/StartChatButton';
import { Avatar } from '../global';
import { CategoryList } from './CategoryList';
import './expertItem.scss';
import { StarList } from './StarList';

interface Props {
  clamped?: boolean;
  expert: Expert;
}

export const ExpertItem: React.FC<Props> = ({ clamped, expert }) => {
  const { translate } = useContext(TranslationContext);

  const {
    available,
    categories,
    country,
    summary,
    description,
    discounted,
    minuteRate,
    name,
    originalMinuteRate,
    totalRating,
  } = expert;

  return (
    <IonRow>
      <IonCol size="12">
        <IonItem>
          <Avatar className="ion-margin-vertical" user={expert} />
          <IonLabel className="ion-text-wrap overflow-visible ion-padding-start">
            <h2>
              {name}{' '}
              <IonText color={available ? 'success' : 'danger'}>
                <small>
                  {available
                    ? translate('expert.status.online')
                    : translate('expert.status.offline')}
                </small>
              </IonText>
            </h2>
            <p>
              <small className="country" color="medium">
                {country}
              </small>
            </p>
            <StarList rating={totalRating} />
            <CategoryList categories={categories} />
          </IonLabel>
        </IonItem>
      </IonCol>
      <IonCol size="12">
        <div
          className={'expert-summary'}
          dangerouslySetInnerHTML={{ __html: summary }}
        />
        {!clamped ? (
          <div
            className={'expert-description'}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        ) : (
          ''
        )}
      </IonCol>
      <IonCol size="12" className="ion-text-center gray">
        {discounted ? (
          <Fragment>
            <IonText>
              <small className="striked-minute-rate">
                {originalMinuteRate} credit{originalMinuteRate > 1 ? 's' : ''}
                /min
              </small>
            </IonText>
            <IonText color="danger">
              <small>
                {minuteRate} credit{minuteRate > 1 ? 's' : ''}/min
              </small>
            </IonText>
          </Fragment>
        ) : (
          <IonText>
            <small>
              {minuteRate} credit{minuteRate > 1 ? 's' : ''}/min
            </small>
          </IonText>
        )}
      </IonCol>
      <IonCol size="12">
        <StartChatButton text={translate('expert.startChat')} expert={expert} />
      </IonCol>
    </IonRow>
  );
};
