import { IonItem, IonItemDivider, IonLabel, IonList } from '@ionic/react';
import React, { useContext } from 'react';
import { TranslationContext } from '../../contexts';
import { CustomerMetadata, RfmValues } from '../../models';
import { MetadataItemList } from './';
import './metadata.scss';
import { RfmScoreList } from './RfmScoreList';

interface Props {
  metadata: CustomerMetadata;
}

export const MetadataList: React.FC<Props> = ({ metadata }) => {
  const { translate } = useContext(TranslationContext);

  return (
    <IonList>
      <IonItem>
        <IonLabel className={'text-sm'}>
          {translate('metadata.status')}:
        </IonLabel>
        <IonLabel className={'centered ion-text-uppercase'} color={'success'}>
          {metadata.customerStatus}
        </IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel className={'ion-text-wrap text-sm'}>
          {translate('metadata.totalPrice')}:
        </IonLabel>
        <IonLabel className={'centered'}>{metadata.totalPurchased}</IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel className={'ion-text-wrap text-sm'}>
          {translate('metadata.totalRefund')}:
        </IonLabel>
        <IonLabel className={'centered'}>{metadata.totalRefund}</IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel className={'ion-text-wrap text-sm'}>
          {translate('metadata.totalPurchases')}:
        </IonLabel>
        <IonLabel className={'centered'}>{metadata.totalItems}</IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel className={'ion-text-wrap text-sm'}>
          {translate('metadata.totalRefundItems')}:
        </IonLabel>
        <IonLabel className={'centered'}>{metadata.totalRefundItems}</IonLabel>
      </IonItem>
      <RfmScoreList rfmValues={metadata.rfmDetails as RfmValues} />
      <IonItemDivider className={'ion-padding-start ion-padding-top'}>
        {translate('metadata.purchasedItemsHistory')}
      </IonItemDivider>
      <MetadataItemList items={metadata.purchaseItems} type={'purchase'} />
      <IonItemDivider className={'ion-padding-start ion-padding-top'}>
        {translate('metadata.refundItemsHistory')}
      </IonItemDivider>
      <MetadataItemList items={metadata.refundItems} type={'refund'} />
    </IonList>
  );
};
