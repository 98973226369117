import { IonCol, IonRouterOutlet, IonRow } from '@ionic/react';
import React from 'react';
import { Redirect, Route, RouteProps, useRouteMatch } from 'react-router';
import { ConversationPage, DashboardPage } from '../../pages';
import { ExpertSidebar } from '../expert/ExpertSidebar';
import './expertLayout.scss';

const getCustomerRoutes = (path: string): RouteProps[] => [
  {
    path: `${path}/conversation/:idExpert/:idCustomer`,
    component: ConversationPage,
  },
  {
    path: `${path}/dashboard`,
    component: DashboardPage,
    exact: true,
  },
  {
    path,
    render: () => <Redirect to={`${path}/dashboard`} />,
    exact: true,
  },
];

export const ExpertLayout = () => {
  const { path } = useRouteMatch();

  return (
    <IonRow className="expert-layout">
      <IonCol size="3" className="expert-sidebar">
        <ExpertSidebar />
      </IonCol>
      <IonCol size="9">
        <IonRouterOutlet>
          {getCustomerRoutes(path).map((route: RouteProps, key: number) => (
            <Route
              {...{
                key,
                ...route,
              }}
            />
          ))}
        </IonRouterOutlet>
      </IonCol>
    </IonRow>
  );
};
