import { IonButton, IonInput } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { TranslationContext, UserContext } from '../../contexts';
import { authResource } from '../../resource';

interface Props {
  setLoading: (value: boolean) => void;
}

interface FormValues {
  email?: string;
  password?: string;
}

const BasicLogin: React.FC<Props> = ({ setLoading }) => {
  const { translate } = useContext(TranslationContext);
  const { setUserInfos } = useContext(UserContext);
  const [values, setValues] = useState<FormValues>({});
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const signin = async () => {
    if (canSubmit) {
      setLoading(true);
      const { email, password } = values;
      try {
        const response = await authResource.create({
          identification: email,
          password,
        });
        setUserInfos(response);
      } catch (e) {
        setHasError(true);
      }
      setLoading(false);
    }
  };

  const onChange = (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });

    if (hasError) {
      setHasError(false);
    }
  };

  useEffect(() => {
    const { email, password } = values;
    setCanSubmit(!!email && !!password);
  }, [values]);

  return (
    <div>
      {hasError ? <p>{translate('authentication.error')}</p> : null}
      <IonInput
        type="text"
        name="email"
        onIonChange={onChange}
        placeholder={translate('authentication.email')}
        value={values.email}
      />
      <IonInput
        type="password"
        name="password"
        onIonChange={onChange}
        placeholder={translate('authentication.password')}
        value={values.password}
      />
      <IonButton disabled={!canSubmit} type="submit" onClick={signin}>
        {translate('authentication.signinButton')}
      </IonButton>
      <hr />
    </div>
  );
};

export default BasicLogin;
