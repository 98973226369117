import { IonItem, IonList, IonLoading } from '@ionic/react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { CONVERSATION_STATUS } from '../../constants';
import { TranslationContext, UserContext } from '../../contexts';
import browserNotificationHelper from '../../helpers/browserNotification';
import { withConversation } from '../../hoc';
import { Conversation, Expert, Message, MessageType } from '../../models';
import { conversationResource } from '../../resource';
import { ConversationGroup } from './';

interface Props {
  discussion: Message[];
}

const ConversationListView: React.FC<Props> = ({ discussion }: Props) => {
  const { translate } = useContext(TranslationContext);
  const { user } = useContext(UserContext);
  const { pathname } = useLocation();
  // Move to more complicated state object if we have more that 2 types of conversations
  // Gosh, I definitely need to use some more nice storage mechanism here
  const [activeConversations, setActiveConversations] = useState<
    Conversation[]
  >([]);
  const [pastConversations, setPastConversations] = useState<Conversation[]>(
    [],
  );
  const [loading, setLoading] = useState<boolean>(false);

  const fetchConversationsFromAPI = useCallback(async () => {
    setLoading(true);
    const response = await conversationResource.findAll();
    setConversations(response.members);
    setLoading(false);
  }, []);
  const [unreadMsgsByConv, setUnreadMsgsCount] = useState<{
    [key: string]: Message[];
  }>({});

  const setConversations = (conversations: Conversation[]) => {
    const newActiveConversations: Conversation[] = [];
    const newPastConversations: Conversation[] = [];
    let newPriorityConversations: Conversation[] = [];
    conversations.forEach(conversation => {
      if (conversation.status === 'finished') {
        newPastConversations.push(conversation);
      } else {
        newActiveConversations.push(conversation);
      }
    });
    if (newActiveConversations.length > 1) {
      // Sort active Conversations by updatedAt date
      newActiveConversations.sort(
        (a: Conversation, b: Conversation): number => {
          return a.updatedAt < b.updatedAt
            ? 1
            : a.updatedAt > b.updatedAt
            ? -1
            : 0;
        },
      );
      // Extract priority Conversation(s)
      newPriorityConversations = newActiveConversations.filter(
        (conversation, index) => {
          if (conversation.priority === 'high') {
            newActiveConversations.splice(index, 1);
          }
          return conversation.priority === 'high';
        },
      );
    }
    // Merge both array (priority & normal Conversations) and keep priority on top
    setActiveConversations(
      newPriorityConversations.concat(newActiveConversations),
    );
    setPastConversations(newPastConversations);
  };

  useEffect(() => {
    if (
      ['/expert/dashboard'].includes(pathname) ||
      pathname.startsWith('/expert/conversation/')
    ) {
      fetchConversationsFromAPI().then();
    }
  }, [fetchConversationsFromAPI, pathname]);

  const isConversationStopped = (message: Message) =>
    [MessageType.STOP, MessageType.LEAVE].includes(message.type);
  const addNewConversation = async (message: Message) => {
    let conversations = [...activeConversations, ...pastConversations];
    const conversationIri = discussion[discussion.length - 1].conversation;
    const existingConversation = conversations.find(conversation => {
      return conversation['@id'] === conversationIri;
    });
    if (
      !existingConversation ||
      existingConversation.status === 'finished' ||
      isConversationStopped(message)
    ) {
      const conversation = await conversationResource.find(conversationIri);
      if (conversation) {
        if (existingConversation) {
          if (
            existingConversation.status === 'finished' &&
            !isConversationStopped(message)
          ) {
            conversation.status = 'new';
          }
          conversations = conversations.filter(
            item => item['@id'] !== conversationIri,
          );
        }
        conversations.unshift(conversation);
      }
    }

    return setConversations(conversations);
  };

  useEffect(() => {
    const newUnreadMsgs: { [key: string]: Message[] } = {};
    discussion.forEach(message => {
      if (newUnreadMsgs[message.conversation] === undefined) {
        newUnreadMsgs[message.conversation] = [message];
      } else {
        newUnreadMsgs[message.conversation].unshift(message);
      }
      setUnreadMsgsCount(newUnreadMsgs);
    });
    if (discussion.length && discussion[discussion.length - 1]) {
      const message = discussion[discussion.length - 1];
      addNewConversation(message).then();
      browserNotificationHelper.notifyAboutMessage(
        message,
        user as Expert,
        translate,
      );
    }
    // eslint-disable-next-line
  }, [discussion]);

  if (loading) {
    return <IonLoading isOpen message={translate('ui.loading')} />;
  }

  if (!activeConversations.length && !pastConversations.length) {
    return <IonItem>{translate('menu.conversations.empty')}</IonItem>;
  }

  return (
    <IonList class="conversation-lists">
      <ConversationGroup
        conversations={activeConversations}
        displayUnreadMessages={true}
        hasStickyHeader={true}
        headerText={translate('menu.conversations.activeConversations')}
        type={CONVERSATION_STATUS.ACTIVE}
        unreadMsgs={unreadMsgsByConv}
      />
      <ConversationGroup
        conversations={pastConversations}
        hasStickyHeader={false}
        headerText={translate('menu.conversations.pastConversations')}
      />
    </IonList>
  );
};

// TODO: This mechanism was copied blindly. Please, check it, when we have time.
export const ConversationList = withConversation(ConversationListView);
