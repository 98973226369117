import { IonButton, IonText } from '@ionic/react';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { CONVERSATION_STATUS, EXCEPTIONS } from '../../constants';
import {
  ConversationContext,
  PaymentContext,
  TranslationContext,
} from '../../contexts';
import { Conversation } from '../../models';
import { conversationResource } from '../../resource';
import { ChatBubbles } from '../icons';

interface Props {
  className?: string;
  shortCode?: string;
  showAvailability?: boolean;
  text?: string;
}

export const StartChatWithCharacterButton = ({
  text,
  className,
  showAvailability,
  shortCode,
}: Props) => {
  const { translate } = useContext(TranslationContext);
  const { setDisplayPaymentPopup } = useContext(PaymentContext);
  const { readOnly } = useContext(ConversationContext);
  const history = useHistory();

  const startConversation = async (e: any) => {
    e.stopPropagation();

    let conversation;

    try {
      conversation = await conversationResource.findByCharacter(shortCode);

      if (conversation) {
        if (!conversation.affordable) {
          throw new Error(EXCEPTIONS.NO_ENOUGH_CREDIT);
        }
      }
    } catch (error) {
      if (error.message.endsWith(EXCEPTIONS.NO_ENOUGH_CREDIT)) {
        if (!readOnly) {
          setDisplayPaymentPopup(true);
        }
      }
      if (error.message.endsWith(EXCEPTIONS.EXPERT_NOT_AVAILABLE)) {
        // TODO Add error message alert("Not available");
        window.location.replace('/character/{$shortCode}');
      }
    }

    if (conversation) {
      history.replace(
        `/conversation/${(conversation as Conversation).expert.id}/${
          (conversation as Conversation).customer.id
        }`,
        {
          idConversation: '/conversations/' + (conversation as Conversation).id,
          readOnly: [
            CONVERSATION_STATUS.STOP,
            CONVERSATION_STATUS.FINISHED,
          ].includes(conversation.status),
        },
      );
    }
  };

  return (
    <div className={className ? className : ''}>
      <IonButton
        onClick={startConversation}
        shape="round"
        expand="block"
        className={'w-100 ion-margin-bottom'}
      >
        <ChatBubbles />
        <IonText className="ion-padding-start">
          {text ? text : translate('expert.startChat')}
        </IonText>
      </IonButton>
      {showAvailability ? (
        <IonText color={'success'}>
          <small>{translate('chat.expertOnline')}</small>
        </IonText>
      ) : null}
    </div>
  );
};
