import jwt_decode from 'jwt-decode';
import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { authenticationHelper } from '../helpers';
import { Authentication, Customer, Expert } from '../models';
import { customerResource, expertResource } from '../resource';

interface UserContextModel {
  isAuthenticated: boolean;
  isCustomer: boolean;
  isExpert: boolean;
  logout: () => void;
  setUserInfos: (token: Authentication | null) => void;
  user: Expert | Customer | null;
}

export const UserContext = createContext<UserContextModel>({
  isAuthenticated: false,
  isCustomer: false,
  isExpert: false,
  logout: () => null,
  setUserInfos: () => null,
  user: null,
});

interface Props {
  children: ReactNode;
}

export const User: FC<Props> = ({ children }) => {
  const [user, setUser] = useState<Expert | Customer | null>(null);
  const [userInfos, setUserInfos] = useState<Authentication | null>(
    authenticationHelper.getInfos,
  );
  const [roles, setRoles] = useState<string[]>([]);

  const isAuthenticated = useMemo(
    () => !!user && authenticationHelper.isAuthenticated(),
    [user],
  );
  const isExpert = useMemo(() => !!roles && roles.includes('ROLE_EXPERT'), [
    roles,
  ]);
  const isCustomer = useMemo(() => !!roles && roles.includes('ROLE_CUSTOMER'), [
    roles,
  ]);
  const logout = () => {
    setUser(null);
    authenticationHelper.removeInfos();
  };

  useEffect(() => {
    const doFetch = async () => {
      authenticationHelper.setInfos(userInfos as Authentication);

      if (!userInfos) {
        return;
      }

      const { roles: newRoles } = jwt_decode(userInfos.token);
      setRoles(newRoles);
    };

    if (!!userInfos) {
      doFetch();
    }
  }, [userInfos]);

  const refreshUserInfos = useCallback(
    async (id: string) => {
      if (isExpert) {
        setUser(await expertResource.find(id));
      } else {
        setUser(await customerResource.find(id));
      }
    },
    [isExpert],
  );

  useEffect(() => {
    if (!!userInfos && roles.length) {
      const infos = userInfos as Authentication;
      refreshUserInfos(infos['@id']);
    }
  }, [userInfos, roles, refreshUserInfos]);

  return (
    <UserContext.Provider
      value={{
        isAuthenticated,
        isCustomer,
        isExpert,
        logout,
        setUserInfos,
        user,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
