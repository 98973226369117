import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Dashboard } from '../components/expert';
import { UserContext } from '../contexts';

export const DashboardPage: React.FC = () => {
  const history = useHistory();
  const { isAuthenticated, isExpert } = useContext(UserContext);

  useEffect(() => {
    if (!isExpert) {
      history.push('/customer/experts');
    }
  }, [isAuthenticated, isExpert, history]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Dashboard</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <Dashboard />
      </IonContent>
    </IonPage>
  );
};
