import { IonButton, IonCol, IonItem, IonLabel, IonRow } from '@ionic/react';
import React, { useContext } from 'react';
import { PaymentContext, TranslationContext } from '../../contexts';
import { Refresh, Wallet } from '../icons';

interface Props {
  centered?: boolean;
  message?: string;
  onResume?: any;
  showResume?: boolean;
  showWallet?: boolean;
}

export const ChatSystemItem = ({
  message,
  centered,
  showWallet,
  showResume,
  onResume,
}: Props) => {
  const { setDisplayPaymentPopup } = useContext(PaymentContext);
  const { translate } = useContext(TranslationContext);

  return (
    <IonItem lines={'none'} class="ion-text-center ion-margin-bottom">
      {message ? (
        centered ? (
          <IonRow className="chat-item-row">
            <IonCol size="12" className="chat-item-col">
              <div className="chat-item--container">
                <IonLabel className="chat-item-label">
                  <div className="chat-item--container--text ion-text-wrap ion-padding-top">
                    <p>{message}</p>
                  </div>
                </IonLabel>
              </div>
            </IonCol>
          </IonRow>
        ) : (
          <IonLabel
            position={'floating'}
            className={'ion-text-wrap label-wallet'}
          >
            {message}
          </IonLabel>
        )
      ) : null}
      {showWallet ? (
        <IonButton
          onClick={() => setDisplayPaymentPopup(true)}
          fill="solid"
          size={'default'}
          expand="full"
          type="button"
          className={'btn-wallet'}
          data-testid="add-credit-button"
        >
          {' '}
          <Wallet /> &nbsp; {translate('chat.addCredits')}
        </IonButton>
      ) : null}
      {showResume ? (
        <IonButton
          onClick={onResume}
          fill="solid"
          size={'default'}
          expand="full"
          type="button"
          className={'btn-wallet'}
        >
          {' '}
          <Refresh /> &nbsp; {translate('chat.resumeConversation')}
        </IonButton>
      ) : null}
    </IonItem>
  );
};
