interface ContextProps {
  [key: string]: any;
}

export class Collection<T> {
  public context: ContextProps | null = null;
  public id: string = '';
  public members: T[] = [];

  constructor(response: any) {
    const itemsPerPage = 30;

    this.id = response['@id'];
    this.members = response['hydra:member'];

    const view = response['hydra:view'];
    if (view) {
      this.context = {
        itemsPerPage,
        totalItems: response['hydra:totalItems'],
        numberOfPages: Math.ceil(response['hydra:totalItems'] / itemsPerPage),
        current: Collection.getCurrentPage(this.id),
        first: view['hydra:first'],
        last: view['hydra:last'],
        previous: view['hydra:previous'] || view['hydra:first'],
        next: view['hydra:next'] !== this.id ? view['hydra:next'] : undefined,
      };
    }
  }

  static getCurrentPage(currentUri: string): any {
    const match = currentUri.match(/page=(\d)*/);

    return match ? +match[1] : 1;
  }
}
