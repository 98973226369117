export enum MessageType {
  CHATSCRIPT_ACTIVATE = 'chatscript-activate',
  CHATSCRIPT_DEACTIVATE = 'chatscript-deactivate',
  CREDIT_PENDING = 'conversation-credit-pending',
  CREDIT_ADDED = 'credit-added',
  JOIN = 'join',
  RESUME = 'conversation-resume-new',
  RESUME_CREDIT_ADDED = 'conversation-resume-new',
  LEAVE = 'leave',
  INFO = 'info',
  MESSAGE = 'message',
  ONE_MINUTE_LEFT = 'conversation-one-minute-left',
  START = 'conversation-start',
  STOP = 'conversation-stop',
}

export enum AuthorType {
  AI = 'ai',
  CHATSCRIPT = 'chatscript',
  CUSTOMER = 'customer',
  EXPERT = 'expert',
  SYSTEM = 'system',
}

export interface Message {
  '@id': string;
  author?: string;
  authorIdentifier?: string;
  authorType?: AuthorType;
  conversation: string;
  conversationBetween?: object;
  date: string;
  message: string;
  type: MessageType;
}

export interface MercureMessage {
  data: Message;
  id: string;
}
