import {
  IonButton,
  IonButtons,
  IonHeader,
  IonModal,
  IonToolbar,
} from '@ionic/react';
import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router';
import {
  ExternalPayment,
  Payment as PaymentComponent,
} from '../components/payment';
import { analyticsHelper } from '../helpers';
import { webAnalyticsHelper } from '../helpers/webAnalytics';
import { Customer } from '../models';
import { customerResource } from '../resource';
import {
  DeviceContext,
  DeviceContextModel,
  TranslationContext,
  UserContext,
} from './index';
import './payment.scss';

interface PaymentContextModel {
  credits: number;
  minutesToUse: number;
  rechargeLink: string;
  refreshCredits: () => void;
  resetToppedUp: () => void;
  setDisplayPaymentPopup: (value: boolean) => void;
  toppedUpBalance: boolean;
}

export const PaymentContext = createContext<PaymentContextModel>({
  credits: 0,
  minutesToUse: 0,
  refreshCredits: () => null,
  setDisplayPaymentPopup: () => null,
  toppedUpBalance: false,
  resetToppedUp: () => null,
  rechargeLink: '',
});

interface Props {
  children: ReactNode;
}

export const Payment: FC<Props> = ({ children }) => {
  const { isAuthenticated, isCustomer, user } = useContext(UserContext);
  const { isBrowser } = useContext(DeviceContext) as DeviceContextModel;
  const [credits, setCredits] = useState<number>(0);
  const [minutesToUse, setMinutesToUse] = useState<number>(0);
  const [toppedUpBalance, setToppedUpBalance] = useState<boolean>(false);
  const [displayPaymentPopup, setDisplayPaymentPopup] = useState<boolean>(
    false,
  );
  const { translate } = useContext(TranslationContext);
  const { pathname } = useLocation();

  const resetToppedUp = () => setToppedUpBalance(false);
  const closePopIn = () => setDisplayPaymentPopup(false);
  const onTopUp = async () => {
    await refreshCredits();
    setToppedUpBalance(true);
    setDisplayPaymentPopup(false);
  };

  const refreshCredits = useCallback(async () => {
    if (user) {
      const refreshedUser = await customerResource.find(
        (user as Customer)['@id'],
      );
      if (refreshedUser) {
        setCredits(refreshedUser.balance);
        setMinutesToUse(refreshedUser.minutesToUse);
      }
    }
  }, [user]);
  const rechargeLink =
    isAuthenticated && isCustomer && user
      ? (user as Customer).rechargeLink
      : '';

  useEffect(() => {
    if (isAuthenticated && isCustomer && user) {
      setCredits((user as Customer).balance);
      setMinutesToUse((user as Customer).minutesToUse);
    }
  }, [isAuthenticated, isCustomer, user]);

  useEffect(() => {
    if (pathname.startsWith('/customer/experts')) {
      refreshCredits();
      resetToppedUp();
    }
  }, [refreshCredits, pathname]);

  useEffect(() => {
    if (displayPaymentPopup) {
      analyticsHelper.logEvent('payment_open', {});
      webAnalyticsHelper.logEvent('payment_open', {});
    }
  }, [displayPaymentPopup]);

  return (
    <PaymentContext.Provider
      value={{
        credits,
        minutesToUse,
        rechargeLink,
        refreshCredits,
        setDisplayPaymentPopup,
        toppedUpBalance,
        resetToppedUp,
      }}
    >
      {children}

      {isAuthenticated && isCustomer ? (
        <IonModal
          backdropDismiss={true}
          cssClass="payment-modal"
          isOpen={isCustomer && displayPaymentPopup}
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={closePopIn}>
                  {translate('payment.popup.close')}
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          {isBrowser ? (
            <ExternalPayment onClose={closePopIn} />
          ) : (
            <PaymentComponent onClose={closePopIn} onTopUp={onTopUp} />
          )}
        </IonModal>
      ) : null}
    </PaymentContext.Provider>
  );
};
