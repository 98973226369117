import { IonContent, IonSpinner } from '@ionic/react';
import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { DeviceContext, DeviceContextModel, UserContext } from '../../contexts';
import { Content as ContentModel, User } from '../../models';
import { contentResource } from '../../resource';
import { MenuBar } from '../layout';

interface Props {
  slug: string;
}

export const Content: React.FC<Props> = props => {
  const slug = props.slug;
  const [content, setContent] = useState<ContentModel | null>(null);
  const [showLoading, setShowLoading] = useState(true);
  const device = useContext(DeviceContext) as DeviceContextModel;
  const { user: currentUser } = useContext(UserContext);
  const user = currentUser as User;
  const textContent = useMemo(
    () =>
      (content && content.html ? content.html : '')
        .replace(/\[deviceId\]/g, device.uuid)
        .replace(/\[customerId\]/g, user ? user.id : ''),
    [content, device, user],
  );

  useEffect(() => {
    const doFetch = async () => {
      const response = await contentResource.findAll({ slug });
      if (response && response.members.length > 0) {
        setContent(response.members[0]);
        // TODO add a message if page is not loading/not found also if internet is down on mobile we should find a way to keep it locally?
      }
      setShowLoading(false);
    };
    doFetch();
  }, [slug]);

  return (
    <Fragment>
      <MenuBar title={content ? content.title : ''} />
      <IonContent
        className="ion-padding static-page"
        data-testid={'content-container'}
      >
        {showLoading ? <IonSpinner color={'primary'} /> : null}
        {content && content.html ? (
          <div
            className={'text-content'}
            dangerouslySetInnerHTML={{ __html: textContent }}
            data-testid={'text-content'}
          />
        ) : null}
      </IonContent>
    </Fragment>
  );
};
