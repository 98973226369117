import { IonChip, IonSpinner, IonToolbar } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { CONVERSATION_STATUS } from '../../constants';
import { Customer } from '../../models';
import { customerResource } from '../../resource';
import './balanceInformation.scss';

interface Props {
  customer: Customer;
  status: string;
}

export const BalanceInformation: React.FC<Props> = ({ customer, status }) => {
  const [remainingCredits, setCredits] = useState<number>(customer.balance);
  const [remainingMinutes, setMinutesToUse] = useState<number>(
    customer.minutesToUse,
  );
  const [isLoading, setLoading] = useState<boolean>(false);
  // Define class for remainingCredits Chip
  const creditsChipClass =
    remainingCredits > 1
      ? 'success'
      : remainingCredits > 0
      ? 'warning'
      : 'danger';
  // Define class for remainingFreeMinutes Chip
  const minutesChipClass =
    remainingMinutes > 1
      ? 'success'
      : remainingMinutes > 0
      ? 'warning'
      : 'danger';
  // Define chip class for status
  const statusChipClass =
    status === CONVERSATION_STATUS.START
      ? 'success'
      : status === CONVERSATION_STATUS.STOP
      ? 'danger'
      : 'warning';
  const isDebug = process.env.REACT_APP_DEBUG === 'true';

  // Refresh Customer credits information every 30 seconds.
  useEffect(() => {
    setCredits(customer.balance);
    setMinutesToUse(customer.minutesToUse);
    const timer = window.setInterval(refreshCreditsInformation, 30000);

    return () => clearInterval(timer);
  }, [customer]);

  const refreshCreditsInformation = async () => {
    setLoading(true);
    const refreshedCustomer = await customerResource.find(customer['@id']);
    if (refreshedCustomer) {
      setCredits(refreshedCustomer.balance);
      setMinutesToUse(refreshedCustomer.minutesToUse);
    }
    setLoading(false);
  };

  return (
    <IonToolbar className="text-center">
      {isDebug ? (
        <IonChip
          color={statusChipClass}
          outline={true}
        >{`${status.toUpperCase()}`}</IonChip>
      ) : null}
      {isLoading ? (
        <IonChip color={creditsChipClass}>
          <IonSpinner name={'lines-small'} color={creditsChipClass} />
          {` credit${remainingCredits > 1 ? 's' : ''} left`}
        </IonChip>
      ) : (
        <IonChip color={creditsChipClass}>
          {`${remainingCredits} credit${remainingCredits > 1 ? 's' : ''} left`}
        </IonChip>
      )}
      {isLoading ? (
        <IonChip color={minutesChipClass}>
          <IonSpinner name={'lines-small'} color={minutesChipClass} />
          {` extra min left`}
        </IonChip>
      ) : (
        <IonChip color={minutesChipClass}>
          {`${remainingMinutes} extra min left`}
        </IonChip>
      )}
    </IonToolbar>
  );
};
