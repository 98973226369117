import {
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React, { useContext } from 'react';
import { ConversationList } from '../components/conversations/ConversationList';
import { TranslationContext } from '../contexts';
import './expertsPage.scss';

export const PastConversationsPage: React.FC = () => {
  const { translate } = useContext(TranslationContext);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton autoHide={false} color="primary" />
          </IonButtons>
          <IonTitle color="primary">{translate('menu.links.chats')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="expert-list ion-padding">
        <IonCard>
          <ConversationList />
        </IonCard>
      </IonContent>
    </IonPage>
  );
};
