import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React, { useContext } from 'react';
import { PaymentContext, TranslationContext } from '../../contexts';
import { Wallet } from '../icons';
import './menubar.scss';

interface MenuBarInterface {
  backLink?: string;
  title: string;
}

export const MenuBar: React.FC<MenuBarInterface> = ({ backLink, title }) => {
  const { translate } = useContext(TranslationContext);
  const { credits, setDisplayPaymentPopup } = useContext(PaymentContext);

  const showPaymentList = () => setDisplayPaymentPopup(true);

  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          {backLink ? (
            <IonBackButton color="primary" defaultHref="/customer/experts" />
          ) : (
            <IonMenuButton autoHide={false} color="primary" />
          )}
        </IonButtons>
        <IonTitle color="primary">{title}</IonTitle>
        <IonButton onClick={showPaymentList} slot="end">
          <Wallet /> {translate('currency', { amount: credits.toFixed(2) })}
        </IonButton>
      </IonToolbar>
    </IonHeader>
  );
};
