import {
  IonCol,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonRow,
  IonText,
} from '@ionic/react';
import classnames from 'classnames';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { doneAll } from 'ionicons/icons';
import React, { useContext, useMemo } from 'react';
import { UserContext } from '../../contexts';
import { AuthorType, Message, MessageType } from '../../models';

interface Props {
  isLast: boolean;
  item: Message;
}

export const ChatItem = ({ isLast, item }: Props) => {
  const { user, isExpert } = useContext(UserContext);
  const { author, authorType, message, type, date } = item;

  const isMe = useMemo(() => {
    return user && author === user.name;
  }, [author, user]);
  const isInfo = useMemo(() => type === MessageType.INFO, [type]);
  const isChatscriptInfo = useMemo(
    () =>
      [
        MessageType.CHATSCRIPT_ACTIVATE,
        MessageType.CHATSCRIPT_DEACTIVATE,
      ].includes(type),
    [type],
  );
  const sentByChatscript = useMemo(() => {
    return authorType === AuthorType.CHATSCRIPT;
  }, [type]);
  const sentByAI = useMemo(() => {
    return authorType === AuthorType.AI;
  }, [type]);
  const isJoin = useMemo(() => type === MessageType.JOIN, [type]);
  const isCreditAdd = useMemo(() => type === MessageType.CREDIT_ADDED, [type]);
  const isLeave = useMemo(() => type === MessageType.LEAVE, [type]);
  const isStart = useMemo(() => type === MessageType.START, [type]);
  const isWarning = useMemo(
    () =>
      [MessageType.ONE_MINUTE_LEFT, MessageType.CREDIT_PENDING].includes(type),
    [type],
  );
  const isAlert = useMemo(() => type === MessageType.STOP, [type]);
  const isMessage = useMemo(() => type === MessageType.MESSAGE, [type]);
  const isResumed = useMemo(() => type === MessageType.RESUME, [type]);
  const isVisible = useMemo(
    () =>
      isExpert ||
      (!isJoin && !isLeave && !isCreditAdd && !isResumed && !isChatscriptInfo),
    [isCreditAdd, isExpert, isJoin, isLeave, isResumed, isChatscriptInfo],
  );
  dayjs.extend(relativeTime);

  if (!isVisible) {
    return null;
  }

  return (
    <IonItemSliding>
      <IonItem
        className={classnames('chat-item', {
          'chat-item--alert': isAlert,
          'chat-item--info': isInfo || isJoin,
          'chat-item--message': isMessage,
          'chat-item--right': isMessage && !isMe,
          'chat-item--start': isStart,
          'chat-item--warning': isWarning,
        })}
      >
        <IonRow className="chat-item-row">
          <IonCol size="12" className="chat-item-col">
            <div className="chat-item--container">
              <IonLabel className="chat-item-label">
                <div className="chat-item--container--text">
                  <p>
                    {sentByChatscript && isExpert ? '🤖 ' : ''}
                    {sentByAI && isExpert ? '🤖🧠 ' : ''}
                    {message}
                  </p>
                </div>
              </IonLabel>
            </div>
          </IonCol>
        </IonRow>
      </IonItem>
      {isMessage && isLast ? (
        <IonRow>
          <IonCol size="12">
            <div
              className={classnames('timer-item', {
                'timer-item--right': !isMe,
              })}
            >
              <p className="timer-item--text">
                {dayjs(new Date(date)).fromNow()}
              </p>
              <IonIcon icon={doneAll} className="first-tick" />
            </div>
          </IonCol>
        </IonRow>
      ) : (
        <IonRow>
          <IonCol size="12" />
        </IonRow>
      )}
      <IonItemOptions className="chat-item-ion-options">
        <IonItemOption>
          <IonText className="chat-item-time">
            {new Date(date).toLocaleTimeString(navigator.language, {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </IonText>
        </IonItemOption>
      </IonItemOptions>
    </IonItemSliding>
  );
};
