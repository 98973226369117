/* eslint-disable */
import {
  IAPProduct,
  IAPQueryCallback,
  InAppPurchase2,
} from '@ionic-native/in-app-purchase-2';
import {
  IonButton,
  IonCol,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonRouterLink,
  IonText,
} from '@ionic/react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  ErrorHandlerContext,
  PaymentContext,
  TranslationContext,
} from '../../contexts';
import { analyticsHelper, consoleHelper } from '../../helpers';
import { webAnalyticsHelper } from '../../helpers/webAnalytics';
import { Package } from '../../models';
import { invoiceResource, packageResource } from '../../resource';
import { Loading } from '../layout';

const store = InAppPurchase2;

interface Props {
  onClose: () => void;
  onTopUp: () => void;
}

export const Payment: React.FC<Props> = ({ onClose, onTopUp }) => {
  const { translate } = useContext(TranslationContext);
  const { credits, refreshCredits } = useContext(PaymentContext);
  const { addToQueue } = useContext(ErrorHandlerContext);
  const [packages, setPackages] = useState<Package[]>([]);
  const [isLoading, setLoadingStatus] = useState<boolean>(false);
  const pay = (productId: string) => {
    setLoadingStatus(true);
    store.order(productId);
  };

  const forceUpdate = useCallback(() => {
    store.refresh();
  }, []);

  useEffect(() => {
    setLoadingStatus(true);
    // SHOW DEBUG MODE ONLY WHEN DEBUG IS ACTIVATED
    store.verbosity =
      process.env.REACT_APP_DEBUG === 'true' ? store.DEBUG : store.QUIET;
    store.error((error: any) => {
      consoleHelper.log('error', error);
      addToQueue(translate('global.appStoreConnectionError'));
      setLoadingStatus(false);
    });

    const doFetch = async () => {
      const response = await packageResource.findAll({ hidden: false });

      response.members.forEach((packageItem: Package) => {
        store
          .when(packageItem.appleStoreProductName)
          .approved((product: IAPProduct) => {
            consoleHelper.log('approved', product.id);

            store.validator = async (transaction: string | IAPProduct) => {
              const receipt = transaction as IAPProduct;
              consoleHelper.log('try to validate', receipt.id, receipt);

              if (receipt.type === store.CONSUMABLE) {
                consoleHelper.log(
                  'launch invoice',
                  packageItem.appleStoreProductName,
                );
                try {
                  await invoiceResource.create({
                    package: packageItem['@id'],
                    // @ts-ignore
                    receiptData: receipt.transaction.appStoreReceipt,
                    success: true,
                  });
                  analyticsHelper.logEvent('buy', {
                    product: product.id,
                    price: product.price,
                    currency: product.currency,
                    credits: packageItem.price,
                  });
                  webAnalyticsHelper.logEvent('buy', {
                    product: product.id,
                    price: product.price,
                    currency: product.currency,
                    credits: packageItem.price,
                  });
                  consoleHelper.log('verified', product.id);
                  refreshCredits();
                  onTopUp();
                  onClose();
                } catch (e) {
                  consoleHelper.log('error API', e);
                  addToQueue(translate('global.paymentError'));
                }
              }

              consoleHelper.log('finish', packageItem.appleStoreProductName);
              product.finish();
              setLoadingStatus(false);
            };

            product.verify();
          });

        store.when(packageItem.appleStoreProductName).cancelled(async () => {
          consoleHelper.log('cancel', packageItem.appleStoreProductName);
          await invoiceResource.create({
            package: packageItem['@id'],
            success: false,
          });
          setLoadingStatus(false);
        });

        store
          .when(packageItem.appleStoreProductName)
          .loaded((product: IAPProduct) => {
            consoleHelper.log('loaded', packageItem.appleStoreProductName);
            forceUpdate();
          });

        store.register({
          id: packageItem.appleStoreProductName,
          type: store.CONSUMABLE,
        });

        store.refresh();
      });

      setPackages(response.members);
      setLoadingStatus(false);
    };

    doFetch();
  }, []);

  return (
    <IonContent className="payment-container">
      <h2>
        {credits === 0
          ? translate('payment.popup.title')
          : translate('payment.popup.myBalance') +
            translate('currency', { amount: credits.toFixed(2) })}
      </h2>
      <IonList>
        <IonListHeader>
          <IonLabel>
            {credits === 0
              ? translate('payment.popup.textNoCredit')
              : translate('payment.popup.textAddCredit')}
          </IonLabel>
        </IonListHeader>
        {packages.map(item => {
          const product = store.get(item.appleStoreProductName);
          return (
            <IonItem key={item.appleStoreProductName}>
              <IonCol size="12">
                <IonButton
                  type="submit"
                  expand="full"
                  size="small"
                  color="light"
                  shape="round"
                  onClick={() => pay(product.id)}
                  title={product.description}
                >
                  <div className="color-primary">
                    <span>{product.title}</span>
                  </div>
                </IonButton>
              </IonCol>
            </IonItem>
          );
        })}
      </IonList>
      <IonText>
        <p className={'small currency-footer'}>
          {translate('payment.popup.currencyFooter')}
        </p>
        <p className="cancel-button">
          <IonRouterLink
            className="d-block ion-text-center pt-2"
            color="medium"
            onClick={onClose}
          >
            {translate('payment.popup.cancel')}
          </IonRouterLink>
        </p>
      </IonText>
      <Loading isLoading={isLoading} />
    </IonContent>
  );
};
