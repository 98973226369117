import React, { useEffect, useState } from 'react';
import { resourceHelper } from '../../helpers';
import { Character as CharacterModel } from '../../models';
import { characterResource } from '../../resource';
import { CharacterItem } from './CharacterItem';

interface Props {
  code: string;
}

export const CharacterShow: React.FC<Props> = ({ code }) => {
  const [character, setCharacter] = useState<CharacterModel | null>(null);

  useEffect(() => {
    const doFetch = async () => {
      setCharacter(
        await characterResource.find(
          resourceHelper.getIriFromIdAndResource(code, characterResource),
        ),
      );
    };

    doFetch();
  }, [code]);

  return character ? <CharacterItem character={character} /> : null;
};
