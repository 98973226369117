import { Plugins } from '@capacitor/core';
const isLogActive = false; /* DESACTIVATED FOR THE TIME BEING AS PACKAGE UPDATE NO COMPATIBLE
  process.env.REACT_APP_DEBUG === 'false' &&
  process.env.REACT_APP_ENV === 'prod' &&
  document.URL.startsWith('capacitor');*/

export const analyticsHelper = {
  logEvent: (name: string, parameters: object) => {
    if (isLogActive) {
      Plugins.CapacitorFirebaseAnalytics.logEvent({ name, parameters });
    }
  },

  setUser: (userId: string) => {
    if (isLogActive) {
      Plugins.CapacitorFirebaseAnalytics.setUserId({ userId });
    }
  },

  setScreenName: (name: string, className: string) => {
    if (isLogActive) {
      Plugins.CapacitorFirebaseAnalytics.setScreenName({
        screenName: name,
        screenClassOverride: className,
      });
    }
  },
};
