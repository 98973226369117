import {
  IonContent,
  IonFab,
  IonFabButton,
  IonFooter,
  IonIcon,
  IonList,
  IonTextarea,
  IonToolbar,
} from '@ionic/react';
import { send as sendIcon } from 'ionicons/icons';
import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CONVERSATION_STATUS } from '../../constants';
import {
  ConversationContext,
  PaymentContext,
  TranslationContext,
  UserContext,
} from '../../contexts';
import { analyticsHelper, conversationHelper } from '../../helpers';
import { webAnalyticsHelper } from '../../helpers/webAnalytics';
import { AuthorType, Expert, Message, MessageType, User } from '../../models';
import { conversationResource } from '../../resource';
import './chat.scss';
import { ChatItem } from './ChatItem';
import { ChatSystemItem } from './ChatSystemItem';

interface Props {
  discussion: Message[];
  send: (
    conversation: string,
    message: string,
    type?: MessageType,
    author?: string,
    authorIdentifier?: string,
    authorType?: string,
    conversationBetween?: object,
  ) => void;
}

export const Chat: React.FC<Props> = ({ discussion, send }) => {
  const {
    idConversation,
    readOnly,
    target,
    status,
    setStatus,
    setConversation,
    conversation,
  } = useContext(ConversationContext);
  const { translate } = useContext(TranslationContext);
  const { isCustomer, isExpert, user: currentUser } = useContext(UserContext);
  const user = currentUser as User;

  const {
    toppedUpBalance,
    credits,
    minutesToUse,
    refreshCredits,
    resetToppedUp,
  } = useContext(PaymentContext);
  const [message, setMessage] = useState<string | null>(null);
  const inputMessage = useRef<HTMLIonTextareaElement>(null);
  const chatContent = useRef<HTMLIonContentElement>(null);
  const [addCreditButtonVisible, setAddCreditButtonVisible] = useState<boolean>(
    false,
  );
  const [isWelcomeMessage, setIsWelcomeMessage] = useState<boolean>(false);
  const [isResumeMessage, setIsResumeMessage] = useState<boolean>(false);
  const expert = target as Expert;

  const sendMessage = () => {
    if (!readOnly && message) {
      send(
        idConversation,
        message as string,
        MessageType.MESSAGE,
        user.name,
        user.authorIdentifier,
        isCustomer ? AuthorType.CUSTOMER : AuthorType.EXPERT,
        [user.authorIdentifier, expert.authorIdentifier],
      );
      setMessage(null);
    }
  };

  const onChange = ({ target: input }: any) => setMessage(input.value);
  const onClick = () => sendMessage();
  const onInput = (event: React.KeyboardEvent<HTMLIonTextareaElement>) => {
    if (event.key === 'Enter') {
      if (event.ctrlKey) {
        // TODO: Insert it at cursor position (can not access selectionStart or something like that)
        event.currentTarget.value += '\r\n';
        return true;
      }
      event.preventDefault();
      sendMessage();
    }
  };
  // When customer click on resume conversation
  const onResume = () => {
    setIsResumeMessage(true);
    const resumeConversation = async () => {
      setConversation(await conversationResource.resume(idConversation));
    };
    const justJoinedMessages = [MessageType.JOIN, MessageType.CREDIT_ADDED];
    const targetLastJoinIndex = conversationHelper.getLastOfType(
      discussion,
      justJoinedMessages,
      expert.name,
    );
    const targetLastLeaveIndex = conversationHelper.getLastOfType(
      discussion,
      [MessageType.LEAVE],
      expert.name,
    );
    const isExpertJoinedConversation =
      targetLastJoinIndex > targetLastLeaveIndex;

    if (isExpertJoinedConversation) {
      // Both are connected
      resumeConversation();
      setIsResumeMessage(false);
    } else {
      // Expert not there, hide resume button and notify expert that customer joined the chat
      const doSend = async () => {
        resumeConversation().then();
        // Mey be this notification will have to be removed
        await send(
          idConversation,
          translate('chat.resume', { name: user.name }),
          MessageType.RESUME,
          user.name,
          user.authorIdentifier,
          AuthorType.SYSTEM,
          [user.authorIdentifier, expert.authorIdentifier],
        );
        analyticsHelper.logEvent('chat_resume', {
          UserName: user.name,
          Target: target!.name,
          Credits: credits,
        });
        webAnalyticsHelper.logEvent('chat_resume', {
          UserName: user.name,
          Target: target!.name,
          Credits: credits,
        });
        setStatus(CONVERSATION_STATUS.ACTIVE);
      };

      doSend().then();
    }
  };

  const isActive = useMemo(
    () =>
      [
        CONVERSATION_STATUS.ACTIVE,
        CONVERSATION_STATUS.CREDIT_ADDED,
        CONVERSATION_STATUS.START,
        CONVERSATION_STATUS.CREDIT_PENDING,
        CONVERSATION_STATUS.RESUME,
      ].includes(status),
    [status],
  );
  const isPending = useMemo(
    () => status === CONVERSATION_STATUS.CREDIT_PENDING,
    [status],
  );
  const isStopped = useMemo(() => status === CONVERSATION_STATUS.STOP, [
    status,
  ]);
  const isResumed = useMemo(() => status === CONVERSATION_STATUS.RESUME, [
    status,
  ]);

  // Join message publication
  useEffect(() => {
    const doSend = async () => {
      await send(
        idConversation,
        translate('chat.join', { name: user.name }),
        MessageType.JOIN,
        user.name,
        user.authorIdentifier,
        AuthorType.SYSTEM,
        [user.authorIdentifier, expert.authorIdentifier],
      );
      analyticsHelper.logEvent('chat_start', {
        UserName: user.name,
        Target: target!.name,
        Credits: credits,
      });
      webAnalyticsHelper.logEvent('chat_start', {
        UserName: user.name,
        Target: target!.name,
        Credits: credits,
      });
    };

    if (!readOnly) {
      doSend();
    }

    // eslint-disable-next-line
  }, [readOnly]);

  // Resume after adding credit
  useEffect(() => {
    if (idConversation && isCustomer && toppedUpBalance) {
      send(
        idConversation,
        translate('chat.availableCredit'),
        MessageType.CREDIT_ADDED,
        user.name,
      );
      const resumeConversation = async () =>
        setConversation(await conversationResource.resume(idConversation));
      resumeConversation();
      resetToppedUp();
      analyticsHelper.logEvent('chat_resume_credit_added', {
        UserName: user.name,
        Target: target!.name,
        Credits: credits,
      });
      webAnalyticsHelper.logEvent('chat_resume_credit_added', {
        UserName: user.name,
        Target: target!.name,
        Credits: credits,
      });
    }
    // eslint-disable-next-line
  }, [
    idConversation,
    isCustomer,
    send,
    translate,
    user,
    toppedUpBalance,
    resetToppedUp,
  ]);

  // check conversation status
  useEffect(() => {
    let newStatus = CONVERSATION_STATUS.ACTIVE;
    let i = discussion.length - 1;
    while (newStatus === CONVERSATION_STATUS.ACTIVE && i >= 0) {
      const currentMessage = discussion[i];

      switch (currentMessage.type) {
        case MessageType.START:
          newStatus = CONVERSATION_STATUS.START;
          setAddCreditButtonVisible(false);
          break;
        case MessageType.CREDIT_ADDED:
          newStatus = CONVERSATION_STATUS.CREDIT_ADDED;
          setAddCreditButtonVisible(false);
          break;
        case MessageType.CREDIT_PENDING:
          newStatus = CONVERSATION_STATUS.CREDIT_PENDING;
          setAddCreditButtonVisible(true); // Set this flag to tell to keep the "add credits" button
          break;
        case MessageType.STOP:
          newStatus = CONVERSATION_STATUS.STOP;
          setAddCreditButtonVisible(false); // Set this flag to tell to hide the "add credits" button
          break;
        case MessageType.RESUME || MessageType.RESUME_CREDIT_ADDED:
          newStatus = CONVERSATION_STATUS.RESUME;
          setAddCreditButtonVisible(false);
          break;
      }

      i--;
    }

    if (i !== 0) {
      setStatus(newStatus);
    }
    // eslint-disable-next-line
  }, [discussion]);

  // When component is unmount, notify that user left conversation and stop the conversation for customer
  useEffect(() => {
    return () => {
      // Remark: this suppose that readOnly is only for past conversation on the expert side
      if (!readOnly && isExpert) {
        send(
          idConversation,
          translate('chat.leave', { name: user.name }),
          MessageType.LEAVE,
          user.name,
          user.authorIdentifier,
          AuthorType.SYSTEM,
          [user.authorIdentifier, expert.authorIdentifier],
        );
        analyticsHelper.logEvent('chat_leave', {
          UserName: user.name,
          Target: target!.name,
          Credits: credits,
        });
        webAnalyticsHelper.logEvent('chat_leave', {
          UserName: user.name,
          Target: target!.name,
          Credits: credits,
        });
      }
    };
    // eslint-disable-next-line
  }, []);

  // start the timer
  useEffect(() => {
    if (!readOnly && discussion.length && user && target) {
      const last = discussion[discussion.length - 1];
      const justJoinedMessages = [
        MessageType.JOIN,
        MessageType.CREDIT_ADDED,
        MessageType.RESUME,
        MessageType.RESUME_CREDIT_ADDED,
      ];
      const justJoined =
        justJoinedMessages.includes(last.type) &&
        (last.author === undefined || last.author === user.name);
      const targetLastJoinIndex = conversationHelper.getLastOfType(
        discussion,
        justJoinedMessages,
        target.name,
      );
      const targetLastLeaveIndex = conversationHelper.getLastOfType(
        discussion,
        [MessageType.LEAVE],
        target.name,
      );
      const lastStartIndex = conversationHelper.getLastOfType(discussion, [
        MessageType.START,
      ]);

      setIsWelcomeMessage(lastStartIndex === -1);

      if (!justJoined || targetLastJoinIndex === -1) {
        setIsResumeMessage(false);
        return;
      }

      const startConversation = async () =>
        setConversation(await conversationResource.start(idConversation));
      if (
        targetLastJoinIndex > targetLastLeaveIndex &&
        status !== CONVERSATION_STATUS.START
      ) {
        startConversation().then();
        setIsResumeMessage(false);
      }
    }
    // eslint-disable-next-line
  }, [discussion, idConversation, user, target, setConversation]);

  // scroll management
  useEffect(() => {
    // we need to set a small delay to have the full size of the render before scrolling
    setTimeout(() => {
      if (
        chatContent &&
        chatContent.current &&
        chatContent.current.scrollToBottom
      ) {
        chatContent.current.scrollToBottom(100);
      }
    }, 800);

    if (inputMessage && inputMessage.current) {
      inputMessage.current.focus();
    }
  }, [discussion]);

  // check inactivity on stop
  useEffect(() => {
    if (isCustomer && (isStopped || isPending)) {
      refreshCredits();
      analyticsHelper.logEvent(isStopped ? 'chat_stopped' : 'chat_paused', {
        UserName: user.name,
        Target: target!.name,
        Credits: credits,
      });
      webAnalyticsHelper.logEvent(isStopped ? 'chat_stopped' : 'chat_paused', {
        UserName: user.name,
        Target: target!.name,
        Credits: credits,
      });
    }

    // eslint-disable-next-line
  }, [isCustomer, isStopped, isPending]);

  return (
    <Fragment>
      <IonContent
        className="chat-content"
        id="chat-content"
        data-testid="chat-content"
        ref={chatContent}
      >
        <IonList lines="none">
          {isCustomer && isWelcomeMessage ? (
            <ChatSystemItem
              message={translate('chat.welcomeMessage', {
                name: target ? target.name : '',
              })}
              centered={true}
            />
          ) : null}
          {discussion.map((item, index) => (
            <ChatItem
              isLast={index === discussion.length - 1}
              item={item}
              key={index}
            />
          ))}
        </IonList>

        {isCustomer && (addCreditButtonVisible || isPending) ? (
          <ChatSystemItem
            message={translate('chat.creditPendingCustomer')}
            showWallet
          />
        ) : null}
        {isCustomer && isStopped && !isResumed ? (
          !minutesToUse &&
          credits < (conversation ? conversation.minuteRate : 0) ? (
            <ChatSystemItem message={translate('chat.topUp')} showWallet />
          ) : minutesToUse ||
            credits >= (conversation ? conversation.minuteRate : 0) ? (
            <ChatSystemItem
              message={translate('chat.stopped')}
              showResume
              onResume={onResume}
            />
          ) : null
        ) : null}
        {isCustomer && isResumeMessage ? (
          <ChatSystemItem
            message={translate('chat.welcomeMessage', {
              name: target ? target.name : '',
            })}
            centered={true}
          />
        ) : null}
      </IonContent>

      <IonFooter className="footer">
        <IonToolbar className="footer-section">
          <div className="footer-container">
            <div className="input-container">
              <IonTextarea
                disabled={readOnly || !isActive}
                placeholder={translate('chat.write')}
                onIonChange={onChange}
                onKeyPress={onInput}
                value={message}
                autofocus={true}
                autoGrow={false}
                rows={1}
                ref={inputMessage}
                className="placeholdertext input-message"
                padding-start
              />
            </div>
            <div className="send-section">
              <IonFab
                vertical="bottom"
                horizontal="end"
                edge={true}
                className="btn-send-container"
              >
                <IonFabButton
                  disabled={readOnly || !isActive}
                  onClick={onClick}
                  className="btn-send"
                  data-testid="send-button"
                  color="primary"
                  mode="md"
                >
                  <IonIcon icon={sendIcon} />
                </IonFabButton>
              </IonFab>
            </div>
          </div>
        </IonToolbar>
      </IonFooter>
    </Fragment>
  );
};
