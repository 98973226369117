import { IonBadge, IonRow } from '@ionic/react';
import React, { useContext } from 'react';
import { TranslationContext } from '../../contexts';
import { Category } from '../../models';

interface ICategoryList {
  categories: Category[];
}

export const CategoryList = ({ categories }: ICategoryList) => {
  const { translate } = useContext(TranslationContext);

  if (0 === categories.length) {
    return (
      <IonBadge color={'medium'} className={'ion-margin-end'}>
        {translate('expert.category.none')}
      </IonBadge>
    );
  }

  return (
    <IonRow>
      {categories.map((category: Category, key: number) => (
        <IonBadge
          {...{
            key,
            color: 'medium',
            className: 'ion-margin-end',
          }}
        >
          {category.name}
        </IonBadge>
      ))}
    </IonRow>
  );
};
