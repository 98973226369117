import { IonItem, IonLabel, IonNote, IonText } from '@ionic/react';
import React, { useContext } from 'react';
import { TranslationContext } from '../../contexts';
import { MetadataItem } from '../../models';
import './metadata.scss';

interface Props {
  items: MetadataItem[];
  type: string;
}

export const MetadataItemList: React.FC<Props> = ({ items, type }) => {
  const { translate } = useContext(TranslationContext);

  return (
    <div>
      {items.length < 1 ? (
        <IonText className={'ion-margin-start ion-padding-top text-sm'}>
          {type === 'purchase'
            ? translate('metadata.noPurchase')
            : translate('metadata.noRefund')}
        </IonText>
      ) : null}
      {items.map((item, index) => {
        return (
          <IonItem key={index} href={item.adminUrl} target={'_blank'}>
            <div>
              <IonLabel className={'ion-text-wrap text-sm'}>
                {item.name}
              </IonLabel>
              <IonNote className={'ion-text-wrap text-sm'} color={'dark'}>
                {item.date} - {item.price} {item.currency}
              </IonNote>
            </div>
          </IonItem>
        );
      })}
    </div>
  );
};
