import { IonItemDivider } from '@ionic/react';
import React, { useContext } from 'react';
import { UserContext } from '../../contexts';
import { Expert } from '../../models';
import { Avatar } from '../global';

export const ExpertHeader: React.FC = () => {
  const { user } = useContext(UserContext);
  const expert = user as Expert;
  return (
    <IonItemDivider>
      <div className="header-image">
        <Avatar user={expert} />
      </div>
      <div className={'ion-color-black'}>&nbsp; {expert.name}</div>
    </IonItemDivider>
  );
};
