import {
  IonCol,
  IonContent,
  IonLoading,
  IonPage,
  IonRow,
  IonToast,
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useLocation } from 'react-router';
import { ExpertShow } from '../components/experts';
import { EXCEPTIONS } from '../constants';
import {
  DeviceContext,
  DeviceContextModel,
  TranslationContext,
  UserContext,
} from '../contexts';
import { analyticsHelper, authenticationHelper } from '../helpers';
import { webAnalyticsHelper } from '../helpers/webAnalytics';
import { authResource, routerResource } from '../resource';
import './readerPage.scss';

interface Props
  extends RouteComponentProps<{
    id: string;
  }> {}

export const ReaderPage: React.FC<Props> = ({ match }) => {
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { translate } = useContext(TranslationContext);
  const device = useContext(DeviceContext) as DeviceContextModel;
  const { setUserInfos } = useContext(UserContext);
  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');

  useEffect(() => {
    const init = async () => {
      await registerOrSignIn();
    };

    init().then();
  }, []);

  const signin = async () => {
    try {
      const response = await authResource.create({
        identification: device.uuid,
        password: process.env.REACT_APP_DEFAULT_DEVICE_AUTH_PASSWORD,
      });
      setUserInfos(response);
      // Add user id to analytics
      analyticsHelper.setUser(response['@id']);
      analyticsHelper.logEvent('login', {
        Device: device.model,
        Version: device.appVersion,
        OS: device.operatingSystem,
      });
      webAnalyticsHelper.setUser(response['@id']);
      webAnalyticsHelper.logEvent('login', {
        Device: device.model,
        Version: device.appVersion,
        OS: device.operatingSystem,
      });
    } catch (error) {
      if (error.message === EXCEPTIONS.INVALID_CREDENTIALS) {
        authenticationHelper.unsetDeviceIdUsed();
      } else {
        setDisplayError(true);
        throw error;
      }
    }
  };

  const registerToken = async () => {
    try {
      const response = await routerResource.create({ token });
      setUserInfos(response);
    } catch (error) {
      setDisplayError(true);
      throw error;
    }
    setLoading(false);

    authenticationHelper.setDeviceIdUsed();
  };

  const registerOrSignIn = async () => {
    setLoading(true);
    setDisplayError(false);
    if (!authenticationHelper.isDeviceIdUsed() && token) {
      try {
        await registerToken();
        analyticsHelper.logEvent('sign_up', {
          Device: device.model,
          Version: device.appVersion,
          OS: device.operatingSystem,
        });
        webAnalyticsHelper.logEvent('sign_up', {
          Device: device.model,
          Version: device.appVersion,
          OS: device.operatingSystem,
        });
      } catch (error) {
        if (error.message === EXCEPTIONS.EMAIL_ALREADY_USED) {
          // sign in with email ?
          setDisplayError(true);
          setLoading(false);
          throw error;
        }
        if (error.message !== EXCEPTIONS.DEVICE_ID_ALREADY_USED) {
          setDisplayError(true);
          setLoading(false);
          throw error;
        }
      }
    } else {
      await signin();
    }
    setLoading(false);
  };
  return (
    <IonPage className="bla-theme">
      <IonContent className="ion-padding">
        <IonRow>
          <IonCol sizeMd="2" className="ion-hide-sm-down" />
          <IonCol sizeMd="8">
            <ExpertShow id={match.params.id} />
            <IonLoading isOpen={loading} message="Please wait..." />
            <IonToast
              isOpen={displayError}
              message={translate('global.serverError')}
              position="bottom"
            />
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};
