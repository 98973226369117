import { IonCol, IonRow } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { EmptyStar, HalfStar, Star } from '../icons';

interface IStarList {
  rating: number;
}

export const StarList = ({ rating }: IStarList) => {
  const [list, setList] = useState<JSX.Element[]>([]);
  useEffect(() => {
    const temporaryList: JSX.Element[] = [];
    for (let i = 0; i < 5; i++) {
      const rest = rating - i;

      if (rest >= 1) {
        temporaryList.push(<Star />);
      } else if (rest < 0.5) {
        temporaryList.push(<EmptyStar />);
      } else {
        temporaryList.push(<HalfStar />);
      }

      setList(temporaryList);
    }
  }, [rating]);
  return (
    <IonRow>
      <IonCol className="ion-no-padding" size="12">
        {list.map((star: JSX.Element, index: number) => (
          <span key={index}>{star}</span>
        ))}
      </IonCol>
    </IonRow>
  );
};
