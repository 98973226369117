import { IonToast } from '@ionic/react';
import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';

export interface ErrorHandlerContextModel {
  addToQueue: (error: string) => void;
}

export const ErrorHandlerContext = createContext<ErrorHandlerContextModel>({
  addToQueue: () => null,
});

interface Props {
  children: ReactNode;
}

export const ErrorHandler: FC<Props> = ({ children }) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [current, setCurrent] = useState<string | null>(null);

  const addToQueue = useCallback(
    (error: string) => {
      setErrors([...errors, error]);
    },
    [errors],
  );

  // take first error and remove it from the list
  useEffect(() => {
    if (!current && errors.length) {
      setCurrent(errors[0]);
      setErrors(errors.slice(1));
    }
  }, [current, errors]);

  return (
    <ErrorHandlerContext.Provider value={{ addToQueue }}>
      {children}
      <IonToast
        color="warning"
        isOpen={!!current}
        duration={10000}
        onDidDismiss={() => setCurrent(null)}
        message={current!}
        showCloseButton
      />
    </ErrorHandlerContext.Provider>
  );
};
