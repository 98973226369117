import {
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
} from '@ionic/react';
import {
  chatbubbles,
  helpBuoy,
  helpCircle,
  informationCircle,
  search,
  sunny,
} from 'ionicons/icons';
import React, { useContext } from 'react';
import { TranslationContext } from '../../contexts';
import './menu.scss';

export const Menu: React.FC = () => {
  const { translate } = useContext(TranslationContext);

  return (
    <IonMenu className="customer-sidebar" contentId="main">
      <IonHeader className="head">{translate('menu.header')}</IonHeader>
      <IonContent>
        <IonList>
          <IonMenuToggle>
            <IonItem button routerLink="/customer/experts">
              <IonIcon slot="start" icon={search} />
              <IonLabel>{translate('menu.links.experts')}</IonLabel>
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle>
            <IonItem button routerLink="/customer/past-conversations">
              <IonIcon slot="start" icon={chatbubbles} />
              <IonLabel>{translate('menu.links.chats')}</IonLabel>
            </IonItem>
          </IonMenuToggle>
          {/*TODO: Create a dynamic list of pages: We could put icon and translate into table and load it dynamically*/}
          <IonMenuToggle>
            <IonItem routerLink="/content/horoscopes">
              <IonIcon slot="start" icon={sunny} />
              <IonLabel mode="md">
                {translate('menu.links.horoscopes')}
              </IonLabel>
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle>
            <IonItem routerLink="/content/howitworks">
              <IonIcon slot="start" icon={helpCircle} />
              <IonLabel>{translate('menu.links.howItWorks')}</IonLabel>
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle>
            <IonItem routerLink="/content/help">
              <IonIcon slot="start" icon={helpBuoy} />
              <IonLabel mode="md">{translate('menu.links.help')}</IonLabel>
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle>
            <IonItem routerLink="/content/about">
              <IonIcon slot="start" icon={informationCircle} />
              <IonLabel mode="md">{translate('menu.links.about')}</IonLabel>
            </IonItem>
          </IonMenuToggle>
          {/** TODO: This button is disabled until we add facebook login feature */}
          {/*<IonMenuToggle>*/}
          {/*  <IonItem routerLink='/logout' detail={false}>*/}
          {/*    <IonIcon slot="start" icon={logOut}/>*/}
          {/*    <IonLabel>{translate('menu.links.logout')}</IonLabel>*/}
          {/*  </IonItem>*/}
          {/*</IonMenuToggle>*/}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};
