import { Conversation } from '../models';
import ApiResource from './ApiResource';
import { Collection } from './Collection';
import { ResourceOptions } from './Resource';

class ConversationResource extends ApiResource<Conversation> {
  public dispatchEndpoint = '/conversations/dispatch';
  public endpoint = '/conversations';

  async findAll(
    params?: any,
    options: ResourceOptions = {},
  ): Promise<Collection<Conversation>> {
    if (!params) {
      params = {};
    }
    if (!params.order) {
      params['order[updatedAt]'] = 'desc';
    }
    if (!params.page && !params.pagination) {
      params.pagination = false;
    }

    return super.findAll(params, options);
  }

  async findByCharacter(shortCode?: string): Promise<Conversation> {
    const response = await fetch(
      super.getUrl(`${this.dispatchEndpoint}/${shortCode}`, null),
      ApiResource.getHeaders({}),
    );

    return super.getResponseOrError(response);
  }

  finish(id: string): Promise<Conversation> {
    return this.update(`${id}/finish`, {});
  }

  resume(id: string): Promise<Conversation> {
    return this.update(`${id}/resume`, {});
  }

  start(id: string): Promise<Conversation> {
    return this.update(`${id}/start`, {});
  }
}

export const conversationResource = new ConversationResource();
