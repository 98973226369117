import { IonButton, IonLoading, IonToast } from '@ionic/react';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { EXCEPTIONS } from '../../constants';
import {
  DeviceContext,
  DeviceContextModel,
  TranslationContext,
  UserContext,
} from '../../contexts';
import { analyticsHelper, authenticationHelper } from '../../helpers';
import { webAnalyticsHelper } from '../../helpers/webAnalytics';
import { authResource } from '../../resource';
import { customerResource } from '../../resource';

interface Props {
  redirect?: string;
  text: string;
}

const DeviceLogin: React.FC<Props> = ({ text, redirect }) => {
  const { setUserInfos } = useContext(UserContext);
  const { translate } = useContext(TranslationContext);
  const device = useContext(DeviceContext) as DeviceContextModel;
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  const signin = async () => {
    try {
      const response = await authResource.create({
        identification: device.uuid,
        password: process.env.REACT_APP_DEFAULT_DEVICE_AUTH_PASSWORD,
      });
      setUserInfos(response);
      // Add user id to analytics
      analyticsHelper.setUser(response['@id']);
      analyticsHelper.logEvent('login', {
        Device: device.model,
        Version: device.appVersion,
        OS: device.operatingSystem,
      });
      webAnalyticsHelper.setUser(response['@id']);
      webAnalyticsHelper.logEvent('login', {
        Device: device.model,
        Version: device.appVersion,
        OS: device.operatingSystem,
      });
    } catch (error) {
      if (error.message === EXCEPTIONS.INVALID_CREDENTIALS) {
        authenticationHelper.unsetDeviceIdUsed();
        await registerOrSignIn();
      } else {
        setDisplayError(true);
        throw error;
      }
    }
    if (redirect) {
      history.push(redirect);
    }
  };

  const register = async () => {
    await customerResource.create({
      deviceId: device.uuid,
      password: process.env.REACT_APP_DEFAULT_DEVICE_AUTH_PASSWORD,
    });
    authenticationHelper.setDeviceIdUsed();
  };

  const registerOrSignIn = async () => {
    setLoading(true);
    setDisplayError(false);
    if (!authenticationHelper.isDeviceIdUsed()) {
      try {
        await register();
        analyticsHelper.logEvent('sign_up', {
          Device: device.model,
          Version: device.appVersion,
          OS: device.operatingSystem,
        });
        webAnalyticsHelper.logEvent('sign_up', {
          Device: device.model,
          Version: device.appVersion,
          OS: device.operatingSystem,
        });
      } catch (error) {
        if (error.message !== EXCEPTIONS.DEVICE_ID_ALREADY_USED) {
          setDisplayError(true);
          setLoading(false);
          throw error;
        }
      }
    }
    await signin();
    setLoading(false);
  };

  return (
    <>
      <IonButton color="primary" onClick={registerOrSignIn}>
        {text}
      </IonButton>
      <IonLoading isOpen={loading} message="Please wait..." />
      <IonToast
        isOpen={displayError}
        message={translate('global.serverError')}
        position="bottom"
      />
    </>
  );
};

export default DeviceLogin;
