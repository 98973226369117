import { IonButton, IonText } from '@ionic/react';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { EXCEPTIONS } from '../../constants';
import {
  ConversationContext,
  PaymentContext,
  TranslationContext,
  UserContext,
} from '../../contexts';
import { resourceHelper } from '../../helpers';
import { Conversation, Expert, User } from '../../models';
import {
  conversationResource,
  customerResource,
  expertResource,
} from '../../resource';
import { ChatBubbles } from '../icons';

interface Props {
  className?: string;
  expert: Expert;
  showAvailability?: boolean;
  text?: string;
}

export const StartChatButton = ({
  text,
  className,
  showAvailability,
  expert,
}: Props) => {
  const { translate } = useContext(TranslationContext);
  const { setDisplayPaymentPopup } = useContext(PaymentContext);
  const { user } = useContext(UserContext);
  const { readOnly } = useContext(ConversationContext);
  const history = useHistory();
  const { '@id': iri, available } = expert;

  const startConversation = async (e: any) => {
    e.stopPropagation();

    let conversation;

    try {
      const conversations = await conversationResource.findAll({ expert: iri });
      if (conversations && conversations.members.length) {
        const last = conversations.members[0];
        if (expert && !expert.available) {
          throw new Error(EXCEPTIONS.EXPERT_NOT_AVAILABLE);
        }
        conversation = await conversationResource.find(last['@id']);
        if (!last.affordable) {
          throw new Error(EXCEPTIONS.NO_ENOUGH_CREDIT);
        }
      } else {
        conversation = await conversationResource.create({ expert: iri });
      }
    } catch (error) {
      if (error.message.endsWith(EXCEPTIONS.NO_ENOUGH_CREDIT)) {
        if (!readOnly) {
          setDisplayPaymentPopup(true);
        }
      }
      if (error.message.endsWith(EXCEPTIONS.EXPERT_NOT_AVAILABLE)) {
        // TODO Add error message alert("Not available");
        window.location.replace('/customer/experts');
      }
    }

    if (conversation) {
      history.replace(
        `/conversation/${resourceHelper.getIdFromIri(
          iri,
          expertResource,
        )}/${resourceHelper.getIdFromIri(
          (user as User)['@id'],
          customerResource,
        )}`,
        { idConversation: (conversation as Conversation)['@id'] },
      );
    }
  };

  return (
    <div className={className ? className : ''}>
      <IonButton
        disabled={!available}
        onClick={startConversation}
        shape="round"
        expand="block"
        className={'w-100 ion-margin-bottom'}
      >
        <ChatBubbles />
        <IonText className="ion-padding-start">
          {text ? text : translate('expert.startChat')}
        </IonText>
      </IonButton>
      {showAvailability ? (
        <IonText color={available ? 'success' : 'danger'}>
          <small>
            {available
              ? translate('chat.expertOnline')
              : translate('chat.expertOffline')}
          </small>
        </IonText>
      ) : null}
    </div>
  );
};
