import {
  IonBadge,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonNote,
} from '@ionic/react';
import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router';
import { CONVERSATION_STATUS, DATE_FORMAT } from '../../constants';
import { TranslationContext } from '../../contexts';
import { resourceHelper } from '../../helpers';
import { Conversation, Message } from '../../models';
import { customerResource, expertResource } from '../../resource';
import { Star, Time } from '../icons';

interface Props {
  conversations: Conversation[];
  displayUnreadMessages?: boolean;
  hasStickyHeader?: boolean;
  headerText?: string;
  type?: string;
  unreadMsgs?: { [key: string]: Message[] };
}

export const ConversationGroup: React.FC<Props> = ({
  conversations = [],
  displayUnreadMessages = false,
  hasStickyHeader = false,
  headerText = 'menu.conversations.pastConversations',
  type,
  unreadMsgs = {},
}) => {
  const { translate, translateDate } = useContext(TranslationContext);
  const history = useHistory();
  const { pathname } = useLocation();
  const joinConversation = ({
    customer,
    expert,
    '@id': id,
    status,
  }: Conversation) => {
    const idCustomer = resourceHelper.getIdFromIri(
      customer['@id'],
      customerResource,
    );
    const idExpert = resourceHelper.getIdFromIri(expert['@id'], expertResource);
    history.push(`/expert/conversation/${idExpert}/${idCustomer}`, {
      idConversation: id,
      readOnly: status === CONVERSATION_STATUS.STOP,
    });
  };

  // TODO Handle infinite scroll for large amount of conversations
  return (
    <IonItemGroup>
      <IonItemDivider className={hasStickyHeader ? 'sticky' : ''}>
        {translate(headerText)}
      </IonItemDivider>
      {!conversations.length && (
        <IonItem>{translate('menu.conversations.empty')}</IonItem>
      )}
      {conversations.map(conversation => {
        const idCustomer = resourceHelper.getIdFromIri(
          conversation.customer['@id'],
          customerResource,
        );
        const idExpert = resourceHelper.getIdFromIri(
          conversation.expert['@id'],
          expertResource,
        );
        const conversationPath = `/expert/conversation/${idExpert}/${idCustomer}`;
        let count = 0;
        if (displayUnreadMessages) {
          if (unreadMsgs[conversation['@id']] !== undefined) {
            for (const message of unreadMsgs[conversation['@id']]) {
              if (
                message.author === undefined ||
                message.author === conversation.expert.name
              ) {
                break;
              }
              count++;
            }
          }
        }

        return (
          <IonItem
            className="conversation-item"
            key={conversation['@id']}
            lines="none"
            onClick={() => joinConversation(conversation)}
            detail
          >
            <div>
              <IonLabel>
                {type === CONVERSATION_STATUS.ACTIVE &&
                conversation.priority === 'high' ? (
                  <Star color={'danger'} />
                ) : null}
                {conversation.customer.name}
              </IonLabel>
              {conversation.updatedAt ? (
                <IonNote>
                  <Time />{' '}
                  {translateDate(conversation.updatedAt, DATE_FORMAT.FULL)}
                </IonNote>
              ) : null}
            </div>
            {displayUnreadMessages &&
            pathname !== conversationPath &&
            count > 0 ? (
              <IonBadge slot="end" color="warning">
                {count}
              </IonBadge>
            ) : null}
          </IonItem>
        );
      })}
    </IonItemGroup>
  );
};
