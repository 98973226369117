import React, { useContext, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router';
import { UserContext } from '../contexts';

export const LogoutPage: React.FC = () => {
  const { isAuthenticated, logout } = useContext(UserContext);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === '/logout') {
      logout();
    }
  }, [logout, pathname]);

  if (isAuthenticated) {
    return null;
  }

  return <Redirect to="/" />;
};
