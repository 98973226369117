import { Translate } from '../contexts';
import { Expert, Message } from '../models';

const NOTIFICATIONS_FREQUENCY_FROM_ONE_CUSTOMER = 600000;

const browserNotificationHelper = {
  recentlyDisplayedFrom: [] as string[],

  notifyAboutMessage(
    message: Message,
    currentUser: Expert,
    translate: Translate,
  ) {
    if (
      Notification.permission !== 'granted' ||
      !currentUser ||
      !message.author ||
      currentUser.name === message.author ||
      this.recentlyDisplayedFrom.includes(message.author)
    ) {
      return null;
    }

    this.recentlyDisplayedFrom.push(message.author);
    setTimeout(() => {
      this.recentlyDisplayedFrom = this.recentlyDisplayedFrom.filter(
        name => name !== message.author,
      );
    }, NOTIFICATIONS_FREQUENCY_FROM_ONE_CUSTOMER);

    const notification = new Notification(
      translate('expert.notificationTitle', { name: message.author }),
      {
        icon: 'https://web.astroconnect.live/assets/icon/apple-touch-icon.png',
        body: message.message,
        timestamp: new Date(message.date).getTime(),
      },
    );
    notification.onclick = function() {
      window.focus();
      this.close();
    };
  },
};

export default browserNotificationHelper;
