import { Conversation } from '../models';
import ApiResource from './ApiResource';

class ChatscriptResource extends ApiResource<Conversation> {
  public endpoint = '/conversations';

  activate(id: string): Promise<Conversation> {
    return this.update(`${id}/start-chatscript`, {}, { method: 'POST' });
  }

  deactivate(id: string): Promise<Conversation> {
    return this.update(`${id}/end-chatscript`, {}, { method: 'POST' });
  }
}

export const chatscriptResource = new ChatscriptResource();
