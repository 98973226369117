import { IonContent, IonItemDivider, IonLabel } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { TranslationContext } from '../../contexts';
import { resourceHelper } from '../../helpers';
import { Customer, CustomerMetadata } from '../../models';
import { customerResource, zendeskResource } from '../../resource';
import { MetadataList, MetadataPlaceholder } from './';
import { ConversationActions } from './ConversationActions';
import './customerMetadataSidebar.scss';
import { ErrorMessage } from './ErrorMessage';

interface Props {
  idConversation: string;
  idCustomer: string;
}

export const CustomerMetadataSidebar: React.FC<Props> = ({
  idCustomer,
  idConversation,
}) => {
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [
    customerMetadata,
    setCustomerMetadata,
  ] = useState<CustomerMetadata | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const { translate } = useContext(TranslationContext);

  const fetchCustomer = async () => {
    const customerRes = await customerResource.find(
      resourceHelper.getIriFromIdAndResource(idCustomer, customerResource),
    );

    return customerRes as Customer;
  };

  const fetchMetadata = async (customerEmail: string) => {
    return await zendeskResource.find('', {
      email: customerEmail,
    });
  };

  useEffect(() => {
    fetchCustomer().then(async customerResponse => {
      setCustomer(customerResponse);
      fetchMetadata(customerResponse.email).then(metadata => {
        setCustomerMetadata(metadata as CustomerMetadata);
        setLoading(false);
      });
    });
    return () => {
      setLoading(true);
    };
  }, [idCustomer]);

  return (
    <IonContent>
      <ConversationActions idConversation={idConversation} />
      <IonItemDivider>
        <IonLabel color={'medium'}>
          {translate('metadata.header', {
            name: customer ? customer.name : 'Customer',
          })}
        </IonLabel>
      </IonItemDivider>
      {isLoading ? <MetadataPlaceholder /> : null}
      {!isLoading && customerMetadata && !customerMetadata.valid ? (
        <ErrorMessage message={customerMetadata.errorMsg} />
      ) : null}
      {!isLoading && customerMetadata && customerMetadata.valid ? (
        <MetadataList metadata={customerMetadata} />
      ) : null}
    </IonContent>
  );
};
