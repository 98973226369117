import { IonRouterOutlet } from '@ionic/react';
import React, { useContext, useEffect } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router';
import { UserContext } from '../contexts/UserContext';
import { analyticsHelper, consoleHelper } from '../helpers';
import {
  CharacterPage,
  ContentPage,
  ConversationPage,
  HomePage,
  LoginPage,
  LogoutPage,
  ReaderPage,
} from '../pages';
import { CustomerLayout, ExpertLayout } from './layout';
import { Menu } from './menu/Menu';

const routes: RouteProps[] = [
  {
    path: '/expert',
    render: () => <ExpertLayout />,
  },
  {
    path: '/customer',
    render: () => <CustomerLayout />,
  },
  {
    path: '/conversation/:idExpert/:idCustomer',
    component: ConversationPage,
  },
  {
    path: '/login',
    component: LoginPage,
    exact: true,
  },
  {
    path: '/logout',
    component: LogoutPage,
    exact: true,
  },
  {
    path: `/reader/:id`,
    component: ReaderPage,
  },
  {
    path: '/home',
    component: HomePage,
  },
  {
    path: '/content/:slug?',
    component: ContentPage,
    exact: true,
  },
  {
    path: '/home',
    component: HomePage,
  },
  {
    path: '/character/:code',
    component: CharacterPage,
  },
  {
    path: '/',
    render: () => <Redirect to="/home" />,
    exact: true,
  },
];

export const Routes = () => {
  const { isCustomer } = useContext(UserContext);
  const location = useLocation();

  useEffect(() => {
    analyticsHelper.setScreenName(location.pathname, 'Routes');
    consoleHelper.log('Location changed: ' + location.pathname);
  }, [location]);

  return (
    <div>
      {isCustomer ? <Menu /> : null}
      <IonRouterOutlet id="main">
        {routes.map((route: RouteProps, key: number) => (
          <Route
            {...{
              key,
              ...route,
            }}
          />
        ))}
      </IonRouterOutlet>
    </div>
  );
};
